import { Button, Checkbox, CircularProgress, FormControl, InputAdornment, InputLabel, MenuItem, Select, Skeleton, Stack, TextField, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import Minus from "../../../../../assets/images/compensation/minus.svg";
import Plus from "../../../../../assets/images/compensation/plus.svg";
import GoalSummaryIcon from "../../../../../assets/images/goalView/goalSummary.svg";
import MilestoneSummaryIcon from "../../../../../assets/images/goalView/milestoneSummary.svg";
import QuIcon from "../../../../../assets/images/goalView/quIcon.svg";
import filterIcon from "../../../../../assets/images/filter.svg";
import { CurrencyFormat, getFormattedDate, isEmptyNullUndefined } from "../../../../utils/utils";
import { useSelector } from "react-redux";
import { IoIosSearch } from "react-icons/io";
import { toast } from "react-toastify";
import { RiErrorWarningFill } from "react-icons/ri";
import axios from "axios";
import { BASE_URL } from "../../../../../api/apiServices";
import APIList from "../../../../../api";
import SideFilters from "../../../../common/sideFilters";
import FilterSelect from "../../../../common/filterSelect";
import PaginationMediBulk from "../../../../common/paginationBulk";
import { MdOutlineClose } from "react-icons/md";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { useHistory } from "react-router-dom";

const ViewGoalsRequest = () => {

    const columns = [
        "Goal Title",
        "Tag",
        "Approval status",
        "Goals Completion",
        "Goal Status",
        "Start Date",
    ]

    const history = useHistory();
    
    const currency = useSelector((state) => state?.empData?.empData?.company?.currency);
    const dateFormat = useSelector((state) => state?.empData?.empData?.company?.dateFormat);
    const userToken = useSelector((state) => state?.user?.userToken);
    const goalcycleData = useSelector((state) => state?.goalCycleReducer?.goalCycle);
    const employeeDetails = useSelector((state) => state?.empData?.empData);
    const activeGoalCycle = useSelector((state) => state?.goalCycleReducer?.activeGoalCycle);

    const [myTeamGoalsCount, setMyTeamGoalsCount] = useState({});
    const [countLoader, setCountLoader] = useState(false);

    const [selectedGoalCycle, setSelectedGoalCycle] = useState({});
    
    const [selectedYearDDReport, setSelectedYearDDReport] = useState('Q1 2024'); 
    const [selectedConfigColumns, setSelectedConfigColumns] = useState(columns);
    const [activeTable, setActiveTable] = useState("detailGoals");
    const [firstTimeLoad, setFirstTimeLoad] = useState(true);

    const [activeTab, setActiveTab] = useState("myTeam");
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [search, setSearch] = useState("");
    const [filterPayload, setFilterPayload] = useState({});
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [isOpenFilter, setIsOpenFilter] = useState(false);
    const [dropdownData, setDropdownData] = useState([]);
    const [dropdownLoader, setDropdownLoader] = useState(false); 

    const [goalsData, setGoalsData] = useState([]);
    const [goalsDataLoader, setGoalsDataLoader] = useState(false);
    const [fixedMapField, setFixedMapField] = useState([]);
    const [checked, setChecked] = useState([]);
    const [showList, setShowList] = useState("All");
    const [approveLoader, setApproveLoader] = useState(false);

 
 const goalYearList = [
        { id: '1', goalYearValue: 'Q1 2024' },
        { id: '2', goalYearValue: 'Q2 2024' },
        { id: '3', goalYearValue: 'Q3 2024' }
    ];

    const perPageItemNames = [10, 20, 50];

    const handleYearDDChange = (event) => {
        setSelectedGoalCycle(event.target.value);
    };

    const handleCollapse = (column) => {
        let temp = structuredClone(selectedConfigColumns);
        temp = temp?.filter((x) => x !== column);
        // } else {
        //     temp.push(column);
        // }
        setSelectedConfigColumns(temp);
    }
    
    useEffect(() => {
        if(!isEmptyNullUndefined(activeGoalCycle?.id)){
            let temp = structuredClone(goalcycleData);
            let active = temp?.filter(x => x?.id === activeGoalCycle?.id)[0];
            setSelectedGoalCycle(active);
        }
    }, [activeGoalCycle])

    useEffect(() => {
        if(!isEmptyNullUndefined(employeeDetails?.id) && !isEmptyNullUndefined(selectedGoalCycle?.id)) {
            
            getDropdownData();
            getMyTeamGoalsCount();
            fetchData(currentPage, filterPayload);

        }
    }, [employeeDetails, selectedGoalCycle]);

    useEffect(() => {
       
        const getdd = setTimeout(() => {
            if (!firstTimeLoad) {
                fetchData(currentPage, filterPayload);
            }
        }, 1000);

        return () => clearTimeout(getdd);
    }, [currentPage])

    useEffect(() => {

        const getSearch = setTimeout(() => {            
            initiateFetchData();
        }, 1000);

        return () => clearTimeout(getSearch);

    }, [search])

    useEffect(() => {

        initiateFetchData();

    }, [activeTab, activeTable, itemsPerPage])

    const initiateFetchData = () => {

        if(!isEmptyNullUndefined(employeeDetails?.id)) {

            if (!firstTimeLoad) {
                if (currentPage !== 0) {
                    setCurrentPage(0);
                } else {
                    fetchData(0, filterPayload);
                }
            }

        }
    }

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

const fetchData = (page, fpayload) => {

    // cancel the request (the message parameter is optional)
    source.cancel('Operation canceled by the user.');

    const payload = {
        
            employeeEligibilityDTO: fpayload,
            employeeId: employeeDetails?.id,
            goalCycleId: selectedGoalCycle?.id,
            companyId: employeeDetails?.company?.id,
            page: activeTable,  // change page name when gos to'teamSummary'
            range: activeTab, // myOrg
            keyword: search
        
    }

    setGoalsDataLoader(true);

    APIList.getGoalsData({
            page: page,
            size: itemsPerPage,
            payload: payload
        })
        .then((res) => {
            setGoalsData(res?.data?.result);
            setFixedMapField(res?.data?.fixedMapField);
            setTotalPages(res?.data?.totalPages);
            setGoalsDataLoader(false);
            setFirstTimeLoad(false);
        })
        .catch((err) => {
            toast.error(
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                    &nbsp;&nbsp;{err?.title}
                </div>
            )
        })
        .finally(() => {
            setGoalsDataLoader(false);
        })
    

    // try {
    //     const response = await axios.post(`${BASE_URL}/goal-employee/my-team-filter?page=${page}&size=${itemsPerPage}`,
    //        payload,
    //         {
    //             headers: {
    //             'Authorization': `Bearer ${userToken?.id_token}`,
    //             'Content-Type': 'application/json',
    //              }, 
    //              cancelToken: source.token    
    //         },
    //     )

    //     // const response = await APIList.getGoalsData({
    //     //     page: page,
    //     //     size: itemsPerPage,
    //     //     payload: payload
    //     // });

    //     if (!response.ok) {
    //         setGoalsDataLoader(false);
    //         throw new Error('Network response was not ok');
    //     }

    //     const result = await response?.data;
    //     setGoalsData(result);
    //     setGoalsDataLoader(false);
    //     setFirstTimeLoad(false);
    // } catch (error) {
    //     toast.error(
    //         <div style={{ display: "flex", flexDirection: "row" }}>
    //             <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
    //             &nbsp;&nbsp;{error?.title}
    //         </div>
    //     )
    //     setGoalsDataLoader(false);
    // }
}

const getDropdownData = () => {
    setDropdownLoader(true);
    APIList.getCGoalsDropdownData({
        companyId: employeeDetails?.company?.id,
        range: activeTab
    })
        .then((res) => {
            setDropdownData(res?.data);
            setDropdownLoader(false);
        }).catch((err) => {
            toast.error(
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                    &nbsp;&nbsp;{err?.title}
                </div>
            )
            setDropdownLoader(false);
        })
}

const getMyTeamGoalsCount = () => {
    setCountLoader(true);
    APIList.mayTeamGoalsCount({
        companyId: employeeDetails?.company?.id,
        goalCycleId: selectedGoalCycle?.id,
        employeeId: employeeDetails?.id
    }).then((res) => {  
        setMyTeamGoalsCount(res?.data);
    }).catch((err) => {
        toast.error(
            <div style={{ display: "flex", flexDirection: "row" }}>
                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                &nbsp;&nbsp;{err?.title}
            </div>
        )
    }).finally(() => {
        setCountLoader(false);
    })
}

const handleCheckboxChange = (e, card) => {
    e.stopPropagation();
    
    let temp = structuredClone(checked);

    if(temp?.includes(card?.goalEmployeeId)){
        temp = temp?.filter(x => x !== card?.goalEmployeeId)
    } else {
        temp.push(card?.goalEmployeeId);
    }        

    setChecked(temp);
};

const handleResetFilters = () => {
    setFilterPayload({});
    if (currentPage !== 0) {
        setCurrentPage(0);
    } else {
        fetchData(0, {});
    }
}

const handleSubmitFilter = () => {
    if (currentPage !== 0) {
        setCurrentPage(0);
    } else {
        fetchData(0, filterPayload);
    }
}

const handleChangeActiveTab = (tab) => {
    if(!goalsDataLoader){
        setActiveTab(tab);
    }
}

const handleApproveGoals = () => {
    if(isEmptyNullUndefined(checked)){
        toast.error(
            <div style={{ display: "flex", flexDirection: "row" }}>
                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                &nbsp;&nbsp;Please select at least one goal
            </div>
        )
        return;
    }
    setApproveLoader(true);
    APIList.approveGoals({
        employeeId: employeeDetails?.id,
        action: true,
        payload: {id: checked, comment: null}
    })
    .then((res) => {
        toast.success(
            <div className="flex flex-row">
              <BsFillCheckCircleFill
                style={{ width: "2rem", height: "2rem" }}
              />
              &nbsp;&nbsp;
              {` Approved successfully`}
            </div>
          );
          fetchData(currentPage, filterPayload);
    })
    .catch((err) => {
        toast.error(
            <div style={{ display: "flex", flexDirection: "row" }}>
                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                &nbsp;&nbsp;{err?.title}
            </div>
        )
    })
    .finally(() => {
        setApproveLoader(false);
    })
}

const handleViewGoal = (row) => {
    history.push({pathname: `/goals/manager/view-goals`, state: {goalId: row?.goalEmployeeId, employeeId: row?.employeeId, goalData: row}});
}

const countShow = useCallback((count) => {
    if(countLoader){
        return <CircularProgress size={21} style={{color:"#FFFFFF"}} />
    } else {
        return count < 10 ? `0${count}` : count;
    }
}, [countLoader]);

    return(
        <div className="view-goals-request">
                  
            <div className="tabs-block">

                <div className="tab-d-flex">
                    <button className={`tab ${activeTab === "myTeam" ? "active" : ""}`} onClick={() => handleChangeActiveTab("myTeam")}>My Team Goals<span>{countShow(myTeamGoalsCount?.myTeam?.detailGoalsCount || 0 + myTeamGoalsCount?.myTeam?.teamSummaryCount || 0)}</span></button>
                    <button className={`tab ${activeTab === "myOrg" ? "active" : ""}`} onClick={() => handleChangeActiveTab("myOrg")}>My Org Goals<span>{countShow(myTeamGoalsCount?.myOrg?.detailGoalsCount || 0 + myTeamGoalsCount?.myOrg?.teamSummaryCount || 0)}</span></button>
                    <button className={`tab ${activeTab === "entireOrg" ? "active" : ""}`} onClick={() => handleChangeActiveTab("entireOrg")}>Entire Org Goals<span>{countShow(myTeamGoalsCount?.entireOrg?.detailGoalsCount || 0 + myTeamGoalsCount?.entireOrg?.teamSummaryCount || 0)}</span></button>
                </div>

                <div className="year-dropDown-div">
                    <FormControl className="year-dropDown">
                        <Select
                            size="small"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedGoalCycle}
                            label="Q2 2024"
                            onChange={handleYearDDChange}
                            renderValue={(selected) => (
                                <span>{selected?.name}</span>
                            )}
                        >
                            {!isEmptyNullUndefined(goalcycleData) && goalcycleData?.map((goalYear) => {
                                return (
                                    <MenuItem value={goalYear}>{goalYear?.name}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </div>

            </div>            
            

            {/*----- Chart layout -----*/}
            <div className="outer-chart-layout">
                {/*----- Goal Summary -----*/}
                <div className="column-layout">
                    <p className="goalMilestoneSummary">Goal Summary</p>

                    <div className="chart-column">
                        <img
                            src={GoalSummaryIcon}
                            alt="GoalSummaryIcon"
                        />

                        <div className="d-block-w100">
                            <div className="sub-outer-chart">
                                <div className="sub-outer-column">
                                    <div className="d-flex">
                                        <div className="status-card status-green" />
                                        <p className="sub-col-value">4 <span className="sub-col-percent-value">(40%)</span></p>
                                    </div>
                                    <p className="sub-col-status">Completed</p>
                                </div>
                                <div className="sub-outer-column">
                                    <div className="d-flex">
                                        <div className="status-card status-yellow" />
                                        <p className="sub-col-value">30 <span className="sub-col-percent-value">(30%)</span></p>
                                    </div>
                                    <p className="sub-col-status">Inprogress</p>
                                </div>
                            </div>

                            <div className="sub-outer-chart mt-18">
                                <div className="sub-outer-column">
                                    <div className="d-flex">
                                        <div className="status-card status-blue" />
                                        <p className="sub-col-value">2 <span className="sub-col-percent-value">(20%)</span></p>
                                    </div>
                                    <p className="sub-col-status">Not Started</p>
                                </div>
                                <div className="sub-outer-column">
                                    <div className="d-flex">
                                        <div className="status-card status-red" />
                                        <p className="sub-col-value">1 <span className="sub-col-percent-value">(10%)</span></p>
                                    </div>
                                    <p className="sub-col-status">Delayed</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*----- Vertical Border -----*/}
                <div className="verticalBorder"></div>

                {/*----- Milestone Summary -----*/}
                <div className="column-layout">
                    <p className="goalMilestoneSummary">Milestone Summary</p>

                    <div className="chart-column">
                        <img
                            src={MilestoneSummaryIcon}
                            alt="MilestoneSummaryIcon"
                        />

                        <div className="d-block-w100">
                            <div className="sub-outer-chart">
                                <div className="sub-outer-column">
                                    <div className="d-flex">
                                        <div className="status-card status-green" />
                                        <p className="sub-col-value">8 <span className="sub-col-percent-value">(40%)</span></p>
                                    </div>
                                    <p className="sub-col-status">Completed</p>
                                </div>
                                <div className="sub-outer-column">
                                    <div className="d-flex">
                                        <div className="status-card status-yellow" />
                                        <p className="sub-col-value">6 <span className="sub-col-percent-value">(30%)</span></p>
                                    </div>
                                    <p className="sub-col-status">Inprogress</p>
                                </div>
                            </div>

                            <div className="sub-outer-chart mt-18">
                                <div className="sub-outer-column">
                                    <div className="d-flex">
                                        <div className="status-card status-blue" />
                                        <p className="sub-col-value">4 <span className="sub-col-percent-value">(20%)</span></p>
                                    </div>
                                    <p className="sub-col-status">Not Started</p>
                                </div>
                                <div className="sub-outer-column">
                                    <div className="d-flex">
                                        <div className="status-card status-red" />
                                        <p className="sub-col-value">2 <span className="sub-col-percent-value">(10%)</span></p>
                                    </div>
                                    <p className="sub-col-status">Delayed</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="tabs-block">

                <div className="tab-d-flex">
                    <button className={`tab ${activeTable === "detailGoals" ? "active" : ""}`} onClick={() => setActiveTable("detailGoals")}>Detail Goals<span>{countShow(myTeamGoalsCount[activeTab]?.detailGoalsCount || 0)}</span></button>
                    <button className={`tab ${activeTable === "teamSummary" ? "active" : ""}`} onClick={() => setActiveTable("teamSummary")}>Team Summary<span>{countShow(myTeamGoalsCount[activeTab]?.teamSummaryCount || 0)}</span></button>
                </div>

                <div className="btn-div">
                    <button className="excel-download">Export in Excel</button>
                    <button className="approve-goals" onClick={handleApproveGoals} disabled={approveLoader}>
                        {approveLoader ? <CircularProgress size={21} style={{color:"#FFFFFF"}} /> : "Approve Goals"}
                    </button>
                </div>

            </div>       

            <div className="goals-tab-btn-div">
                {/*----- Approved goals & Goals under review & Goals Under Draft Tab -----*/}
                <div className="goals-tab">
                    <div>
                        

                        {/*----- All and Selected Count Btn -----*/}
                        {
                            activeTable === "detailGoals" && 
                            <div className="all-selected-count-border">
                                <Button className={`all-select-btn ${showList === "All" && "active-btn"}`} onClick={() => setShowList("All")}>
                                    All
                                </Button>
                                <Button className={`all-select-btn ${showList === "Selected" && "active-btn"}`} onClick={() => setShowList("Selected")}>
                                    Selected ({checked?.length})
                                </Button>
                            </div>
                        }
                    </div>
                    {/* <div className="qu-icon-div">
                        <img
                            src={QuIcon}
                            alt="QuIcon"
                            height={17}
                            width={17}
                        />
                    </div> */}
                </div>

                    
                
            </div>

            <div className="table-bg">
                <div className="table-top-layout">
                    {/*----- Search Bar -----*/}
                    <div className="table-top-column">
                        <div className="search-bar-div">
                            <TextField
                                id="input-with-icon-textfield"
                                placeholder="Search..."
                                // size="small"
                                value={search}
                                fullWidth
                                className="search-bar"
                                onChange={(e) => setSearch(e.target.value)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <IoIosSearch />
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{
                                    width: "20rem",
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            border: 'none',
                                        },
                                    },
                                }}
                            />
                            <MdOutlineClose className="close-icon" onClick={() => setSearch("")} />
                        </div>
                        <div className="table-top-column">

                            <div className="table-filter-dropdown">
                                <button className="filter-tab" 
                                onClick={() => setIsOpenFilter(true)}
                                >
                                    <img src={filterIcon} alt="filter" />{' '}
                                    Filter                                
                                </button>
                            </div>
                    </div>
                    
                    </div>

                    {/*----- Table Fillter -----*/}
                    

                    {/*----- Gap -----*/}
                    <div className="table-top-column"></div>

                    {/*----- Expand All CheckBox -----*/}
                    <div className="table-top-column expand-all" >
                        <Checkbox
                            checked={ columns?.length === selectedConfigColumns?.length }
                            // disabled={ columns?.length === selectedConfigColumns?.length }
                            onChange={() => setSelectedConfigColumns(columns)}
                        />
                        Expand All
                    </div>

                    {/* <div className="submit-Goals-div">
                        <Button variant="outlined" className="submit-Goals-btn">
                            Submit Goals
                        </Button>
                    </div> */}

                   
                </div>

            {
                activeTable === "detailGoals" &&
                <>
           

            {
                (goalsDataLoader) ?
                                   
                <table style={{padding: "1rem", marginTop: "2rem"}}>
                    <tbody>
                        {
                            [...Array(10).keys()].map((y) => {
                                return(
                                        <tr key={y}>
                                            {
                                                columns?.map((x) => {
                                                    return(
                                                        <td style={{padding: "0.5rem"}} key={x}>
                                                            <Skeleton width={100} />
                                                        </td>
                                                    )
                                                })
                                            }
                                        </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>

                    :

                    <div className="table-container-sample">
                   
                        <table>
                        <thead>
                            
                        <tr>
                                <th className={`sticky`}>
                                    <div className="col-head-outer">
                                        <span className="table-heading">Emp Code</span>
                                    </div>    
                                </th>
                                <th className={`sticky2`}>
                                    <div className="col-head-outer">
                                        <span className="table-heading">Emp Name</span>
                                    </div>    
                                </th>
                            {
                                columns?.map((column,index) => {
                                    return(
                                            
                                                selectedConfigColumns?.includes(column) &&
                                                <th className={`${(column === "Emp Code") && "sticky"} ${((column === "Emp Name")) && "sticky2"}`}>
                                                    <div className="col-head-outer">
                                                    {((column === "Emp Code") || (column === "Emp Name")) ? "" : <img className="mr-5" src={!selectedConfigColumns?.includes(column) ? Plus : Minus} alt="Minus" onClick={() =>  handleCollapse(column)} />} 
                                                        <span className="table-heading">{!selectedConfigColumns?.includes(column) ? "" : column}</span>
                                                    </div>    
                                                </th>
                                            
                                    )
                                })
                            }

                            <th className="sticky-right">
                                <div className="col-head-outer">
                                    <span className="table-heading">Action</span>
                                </div>    
                            </th>
                            
                        </tr>
                        </thead>
                        {
                                            <tbody>
                                                {
                                                !isEmptyNullUndefined(fixedMapField) && goalsData?.filter(x => showList === "Selected" ? checked?.includes(x?.goalEmployeeId) : true)?.map((row) => (
                                                    
                                                    <tr key={row?.empCode} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    

                                                                <td className="table-value sticky" component="th" scope="row">
                                                                {
                                                                        row?.status === "SUBMIT" ?
                                                                        <Checkbox
                                                                        checked={checked?.includes(row?.goalEmployeeId)}
                                                                        onChange={(e) => handleCheckboxChange(e, row)}
                                                                        color="primary"
                                                                        sx={{
                                                                            '&.MuiButtonBase-root': {
                                                                                    // padding: 0,
                                                                                },
                                                                            }}
                                                                        />
                                                                        :
                                                                        <Checkbox
                                                                        checked={true}
                                                                        // onChange={(e) => handleCheckboxChange(e, row)}
                                                                        color="primary"
                                                                        disabled
                                                                        sx={{
                                                                            '&.MuiButtonBase-root': {
                                                                                    // padding: 0,
                                                                                },
                                                                            }}
                                                                        />
                                                                    }
                                                                    {row?.empCode}
                                                                </td>
                                                    { 
                                                                <td className="table-value-emp-name sticky2" align="left">
                                                                    {row?.empName}&nbsp;                                                                   
                                                                </td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes("Goal Title") &&
                                                            <td className="table-value" align="left">{row[fixedMapField["title"]]}</td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes("Tag") &&
                                                            <td className="table-value" align="left">{row[fixedMapField["tag"]]}</td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes("Approval status") &&
                                                            <td className="table-value" align="left">{row?.status}</td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes("Goals Completion") &&
                                                            <td className="table-value" align="left">{row?.goalsCompletion}</td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes("Goal Status") &&
                                                            <td className="table-value" align="left">{row[fixedMapField["progressStatus"]]}</td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes("Start Date") &&
                                                            <td className="table-value" align="left">{row[fixedMapField["startDate"]] ? getFormattedDate(row[fixedMapField["startDate"]], dateFormat) : ""}</td>
                                                        }
                                                        {
                                                            <td className="table-value-btns sticky-right" align="left">
                                                                <button 
                                                                className="edit-btn" 
                                                                onClick={(e) => {
                                                                    e.stopPropagation()
                                                                    history.push({pathname: `/employee-goals-form/${row?.goalEmployeeId}/false`, state: {isManagerEdit: true, employeeId: row?.employeeId, managerId: employeeDetails?.id}});
                                                                }}>
                                                                                Edit</button>
                                                                <button className="view-btn" onClick={() => handleViewGoal(row)} >View</button>
                                                            </td>
                                                        }
                                                        
                                                       
                                                    </tr>
                                                
                                                ))}
                                            </tbody>
                                    }
                        <tbody>
                            
                        </tbody>
                        </table>
                        {                   
                            !goalsDataLoader && isEmptyNullUndefined(goalsData) &&
                            <div className="no-data">No data found</div>
                        }
                        </div>

            }
                </>
                }
                {
                    activeTable === "teamSummary" &&
                    <>
                {
                (goalsDataLoader) ?
                                   
                <table style={{padding: "1rem", marginTop: "2rem"}}>
                    <tbody>
                        {
                            [...Array(10).keys()].map((y) => {
                                return(
                                        <tr key={y}>
                                            {
                                                columns?.map((x) => {
                                                    return(
                                                        <td style={{padding: "0.5rem"}} key={x}>
                                                            <Skeleton width={100} />
                                                        </td>
                                                    )
                                                })
                                            }
                                        </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>

                    :

                    <div className="table-container-sample">
                   
                        <table>
                        <thead>
                            
                        <tr>
                                <th className={`sticky`}>
                                    <div className="col-head-outer">
                                        <span className="table-heading">Emp Code</span>
                                    </div>    
                                </th>
                                <th className={`sticky2`}>
                                    <div className="col-head-outer">
                                        <span className="table-heading">Emp Name</span>
                                    </div>    
                                </th>
                                <th>
                                    <div className="col-head-outer">
                                        <span className="table-heading">Goals Under Draft</span>
                                    </div>    
                                </th>
                                <th>
                                    <div className="col-head-outer">
                                        <span className="table-heading">Goals Submitted</span>
                                    </div>    
                                </th>
                                <th>
                                    <div className="col-head-outer">
                                        <span className="table-heading">Goals Approved</span>
                                    </div>    
                                </th>
                          
                            
                        </tr>
                        </thead>
                        {
                                            <tbody>
                                                {
                                                goalsData?.map((row) => (
                                                    <tr key={row?.empCode} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    

                                                                <td className="table-value sticky" component="th" scope="row">{row?.empCode}</td>
                                                    { 
                                                                <td className="table-value-emp-name sticky2" align="left">
                                                                    {row?.empName}&nbsp;                                                                   
                                                                </td>
                                                        }
                                                        
                                                            <td className="table-value" align="left">{row?.draftCount}</td>
                                                        
                                                            <td className="table-value" align="left">{row?.submitCount}</td>
                                                        
                                                            <td className="table-value" align="left">{row?.approvedCount}</td>
                                                       
                                                        
                                                       
                                                    </tr>
                                                
                                                ))}
                                            </tbody>
                                    }
                        <tbody>
                            
                        </tbody>
                        </table>

                        {                   
                            !goalsDataLoader && isEmptyNullUndefined(goalsData) &&
                            <div className="no-data">No data found</div>
                        }
                        </div>

            }
                </>
            }     
            
            {
                !isEmptyNullUndefined(goalsData) && 
                <div className="table-pagination-wrapper">
                <div className="d-flex align-items-center">
                    <Typography className="per-page-item">{"Per page items"}</Typography>
                    <FormControl className="mt-12" size="small"
                        sx={{
                            "& .MuiOutlinedInput-notchedOutline": {
                                border: "1px solid #00425A",
                                borderRadius: "10px",
                            },
                            "& .MuiInputBase-root": {
                                fontSize: "12px",
                                color: "#00425A",
                                fontWeight: 400,
                            }
                        }}>
                        <InputLabel id="demo-simple-select-label" sx={{ fontSize: "12px", color: "#00425A", fontWeight: 400 }}></InputLabel>
                        <Select
                            fullWidth
                            size="small"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={itemsPerPage}
                            onChange={(e) => setItemsPerPage(e.target.value)}
                            sx={{ fontSize: "12px", color: "#00425A", fontWeight: 400 }}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        "& .MuiMenuItem-root": {
                                            fontSize: "12px",
                                            color: "#00425A",
                                            fontWeight: 400,
                                        }
                                    }
                                }
                            }}
                        >
                            {perPageItemNames?.map((report) => {
                                return (
                                    <MenuItem key={report.id} value={report}>{report}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </div>
                {
                    (totalPages > 1) && 
                    <PaginationMediBulk currentPage={currentPage} totalPages={totalPages} onPageChange={setCurrentPage} />
                }
            </div>
            }
            </div>

        {
                isOpenFilter &&
                <SideFilters isOpen={isOpenFilter} setIsOpen={setIsOpenFilter} >
                    <div className="filter-wrapper">

                        <div className="filters-heading">Filters</div>

                        {
                            dropdownLoader ? 
                            <CircularProgress size={29} />
                            :
                            <Stack>
                                {
                                    !isEmptyNullUndefined(dropdownData) && Object.keys(dropdownData).map((key) => {
                                        return (
                                            <FilterSelect title={key} filterPayload={filterPayload} setFilterPayload={setFilterPayload} dropdownData1={dropdownData} />
                                        )
                                    })
                                }
                            </Stack>
                        }

                        <Stack sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            marginTop: "1rem",
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end"
                        }}>

                            <Button
                                variant="outlined"
                                disabled={Object.keys(filterPayload).length === 0}
                                sx={{
                                    color: "var(--primary)",
                                    borderColor: "var(--primary)",
                                    marginRight: "1rem",
                                    width: "9rem"
                                }}
                                onClick={() => { handleResetFilters() }}
                            >
                                Reset
                            </Button>
                            <Button
                                variant="contained"
                                sx={{
                                    // color:"var(--secondary)",
                                    backgroundColor: "var(--primary)",
                                    borderColor: "var(--primary)",
                                    "&:hover": {
                                        backgroundColor: "#035c7c",
                                        borderColor: "#035c7c",
                                    },
                                    width: "9rem"
                                }}
                                disabled={goalsDataLoader || dropdownLoader}
                                onClick={() => handleSubmitFilter()}
                            >
                                Submit
                            </Button>
                        </Stack>
                    </div>
                </SideFilters>
            }
        </div>
    )
}

export default ViewGoalsRequest;
