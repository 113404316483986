import React, { useState } from 'react';
import { Button, Grid, Typography, Drawer, Select, MenuItem, TextField, InputAdornment } from '@mui/material';
import EditUserProfile from "../../../../assets/images/successionPlan/editUserProfile.png";
import DrawerCloseIcon from "../../../../assets/images/successionPlan/drawerCloseIcon.svg";
import searchIcon from "../../../../assets/images/EmployeeProfile/searchIcon.svg";
import DateIcon from "../../../../assets/images/promotions/dateIcon.svg";
import { AiOutlineDown } from "react-icons/ai";


const PromotionFeedbackRightDrawer = ({ anchorRightDrawer, openStateRight, onCloseRightDrawer }) => {

    const totalNumbers = [1, 2, 3, 4, 5];

    return (
        <Drawer
            anchor={anchorRightDrawer}
            open={openStateRight}
        // onClose={onCloseRightDrawer}
        >
            <Grid container spacing={2} className="promotion-feedback-right-drawer">
                <Grid container item xs={12} md={10} sm={10}>
                    <img
                        src={EditUserProfile}
                        alt="EditUserProfile"
                        height={50}
                        width={50}
                    />
                    <Grid className="user-grid">
                        <Typography className="feedback-user-name">{"Ashish Reji"}</Typography>
                        <Typography className="feedback-user-id">{"ID: 26k7"}</Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={2} sm={2} className="ta-right">
                    <img
                        src={DrawerCloseIcon}
                        alt="DrawerCloseIcon"
                        height={17}
                        width={17}
                        onClick={onCloseRightDrawer}
                    />
                </Grid>


                <Grid item xs={12} sm={12} md={12}>
                    <Typography className="title-promotion-feedback">{"Request Promotion Feedback"}</Typography>

                    {/*----- Proposed Job title -----*/}
                    <Typography className='field-label'>{"Proposed Job title"}</Typography>
                    <Select
                        size="small"
                        name="promotionStatus"
                        fullWidth
                        value={"5"}
                        className="text-field-area"
                        IconComponent={AiOutlineDown}
                        sx={{
                            width: '100%',
                            mt: '5px',
                            "& fieldset": {
                                borderRadius: '8px',
                            },
                        }}
                    >
                        {totalNumbers?.map((report) => {
                            return (
                                <MenuItem value={report} key={report}>{report}</MenuItem>
                            )
                        })}
                    </Select>

                    {/*----- Scope of role -----*/}
                    <Typography className='field-label'>{"Scope of role"}</Typography>
                    <TextField
                        name="scopeOfRole"
                        id="outlined-multiline-static"
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={"This employee comes under qualitative feedback, measurable perform"}
                        sx={{
                            width: '100%',
                            mt: '5px',
                            "& fieldset": {
                                borderRadius: '8px',
                            },
                        }}
                    />

                    {/*----- Search bar -----*/}
                    <TextField
                        name="scopeOfRole"
                        id="outlined-multiline-static"
                        variant="outlined"
                        size="small"
                        fullWidth
                        placeholder='Search'
                        value={""}
                        sx={{
                            width: '100%',
                            mt: '20px',
                            "& fieldset": {
                                borderRadius: '8px',
                            },
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <img
                                        src={searchIcon}
                                        className="searchIcon"
                                        alt="Search Icon"
                                    />
                                </InputAdornment>
                            ),
                        }}
                    />

                    {/*----- Select date -----*/}
                    <Typography className='field-label'>{"Request feedback by"}</Typography>
                    <TextField
                        name="requestFeedbackBy"
                        id="outlined-multiline-static"
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={"Select date"}
                        sx={{
                            width: '100%',
                            mt: '5px',
                            "& fieldset": {
                                borderRadius: '8px',
                            },
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <img
                                        src={DateIcon}
                                        className="searchIcon"
                                        alt="Search Icon"
                                    />
                                </InputAdornment>
                            ),
                        }}
                    />

                    {/*----- Your Message -----*/}
                    <Typography className='field-label'>{"Your Message"}</Typography>
                    <TextField
                        name="yourMessage"
                        id="outlined-multiline-static"
                        variant="outlined"
                        placeholder="Enter your message"
                        multiline
                        rows={4}
                        value="I would like him to Thing Big and Strategically as a VP (showcase this in areas outside of the standard input focus)"
                        style={{ width: "100%", marginTop: "5px" }}
                        InputProps={{
                            style: {
                                color: "#7E7E7E",
                                fontSize: "16px",
                                fontWeight: "lighter",
                                backgroundColor: "#FFFFFF",
                                borderRadius: "8px",
                            },
                        }}
                    />

                    {/*----- Upload Supporting documents -----*/}
                    <Typography className='upload-document'>{"+ Upload Supporting documents"}</Typography>


                    {/*----- (File size not more than 10MB) -----*/}
                    <Typography className='file-size-label'>{"(File size not more than 10MB)"}</Typography>

                    <Grid className="button-grid">
                        <button className="cancel-btn">{"Cancel"}</button>
                        <button className="send-request-btn">{"Send F/b Request"}</button>
                    </Grid>
                </Grid>
            </Grid>
        </Drawer>
    );
};

export default PromotionFeedbackRightDrawer;