import { CircularProgress, FormHelperText, Skeleton, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import APIList from "../../../../../api";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import { toast } from "react-toastify";
import { RiErrorWarningFill } from "react-icons/ri";
import Comments from "../../../../common/comments";
import GoalsForm from "../../goalsForm";
import DialogPopup from "../../../../common/Dialog";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { SlClose } from "react-icons/sl";

const ViewGoal = () => {

    const history = useHistory();
    const location = useLocation();

    const employeeDetails = useSelector((state) => state?.empData?.empData);

    const [openDialog, setOpenDialog] = useState(false);
    const [dialogPopupFor, setDialogPopupFor] = useState("");
    const [approveRejectLoader, setApproveRejectLoader] = useState(false);
    const [reason, setReason] = useState("");
    const [reasonErr, setReasonErr] = useState(false);
    const [commentLoader, setCommentLoader] = useState(false);
    const [allComments, setAllComments] = useState([]);
    const [addCommentLoader, setAddCommentLoader] = useState(false);
    const [comment, setComment] = useState("");
    const [getCommentPayload, setGetCommentPayload] = useState({
        employeeIds : [],
        fromDate : null,
        toDate : null,
        sortByDate : "asc"
    });
    
    useEffect(() => {  
        
            getAllComments();
        
    }, [getCommentPayload])

console.log("allComments", allComments)
    const getAllComments = () => {

        let temp = structuredClone(allComments);

            setCommentLoader(true);
            APIList.goalComments(
                 {
                    payload: getCommentPayload,
                    id: location?.state?.goalId
                  }
                )
            .then((res) => {
                
                    if(temp?.some(x => x?.id === location?.state?.goalId)){
                        temp = temp?.map(x => x?.id === location?.state?.goalId ? {...x, comments: res?.data[0]?.entityList} : x);
                    } else {
                        temp.push({
                            id: location?.state?.goalId,
                            comments: res?.data[0]?.entityList
                        })
                    }        
    
                setAllComments(temp);
    
            })
            .catch((err) => {
                toast.error(
                    <div style={{display:"flex", flexDirection:"row"}}>
                    <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                    &nbsp;&nbsp;{err?.message};
                    </div>
                )
            })
            .finally(() => {
                setCommentLoader(false);
            })
        
    }

    const getAllCommentsAfterSave = () => {
        setCommentLoader(true);
        APIList.goalComments({
            payload: getCommentPayload,
            id: location?.state?.goalId
          })
        .then((res) => {

            let temp = structuredClone(allComments);

                let currentGoal = temp?.filter(x => x?.id === location?.state?.goalId)[0];
                currentGoal["comments"] = res?.data[0]?.entityList;

            setAllComments(temp);

        })
        .catch((err) => {
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                &nbsp;&nbsp;{err?.message};
                </div>
            )
        })
        .finally(() => {
            setCommentLoader(false);
        })
    }

    const addComment = (comment) => {
        setAddCommentLoader(true);
        APIList.addCommentOnGoal({
            goalEmployeeId: location?.state?.goalId,
            comment:comment,
            commentByEmployeeId: employeeDetails?.id
        })
        .then((res) => {
            getAllCommentsAfterSave();
            setComment("");
        })
        .catch((err) => {
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                &nbsp;&nbsp;{err?.message};
                </div>
            )
        })
        .finally(() => {
            setAddCommentLoader(false);
        })
    }

    const handleApproveGoals = () => {

        if(dialogPopupFor === "reject" && reason === ""){
            setReasonErr(true);
            return;
        }
      
        setApproveRejectLoader(true);
        APIList.approveGoals({
            employeeId: employeeDetails?.id,
            action: dialogPopupFor === "approve",
            payload: {id: [location?.state?.goalId], comment: reason}
        })
        .then((res) => {
            toast.success(
                <div className="flex flex-row">
                  <BsFillCheckCircleFill
                    style={{ width: "2rem", height: "2rem" }}
                  />
                  &nbsp;&nbsp;
                  {` Approved successfully`}
                </div>
              );
        })
        .catch((err) => {
            toast.error(
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                    &nbsp;&nbsp;{err?.title}
                </div>
            )
        })
        .finally(() => {
            setApproveRejectLoader(false);
            setOpenDialog(false);
            history.push({pathname:`/ViewGoalsRequest`});
        })
    }

    const handleRejectGoal = () => {
        setDialogPopupFor("reject");
        setReason("");
        setReasonErr(false);
        setOpenDialog(true);
    }

    const handleApproveGoal = () => {
        setDialogPopupFor("approve");
        setReason("");
        setReasonErr(false);
        setOpenDialog(true);
    }

    const handleChangeReason = (e) => {
        setReason(e.target.value);
        if(e.target.value === ""){
            setReasonErr(true);
        } else {
            setReasonErr(false);
        }
    }

    return (
        <div className="emp-goals-page">  

            {/*----- Edit & Comment Layout -----*/}
            <div className="outer-edit-comments-layout">
                {/*----- Edit Layout -----*/}
                <div className="edit-card-layout">

                <div className="button-outer-view-top">
                    <button className={`reject-btn ${(location?.state?.goalData?.status === "APPROVED" || location?.state?.goalData?.status === "REJECT") ? "disabled-reject-btn" : ""}`} onClick={handleRejectGoal} disabled={approveRejectLoader || location?.state?.goalData?.status === "APPROVED" || location?.state?.goalData?.status === "REJECT"}>Reject</button>
                    <button className={`approve-btn ${(location?.state?.goalData?.status === "APPROVED" || location?.state?.goalData?.status === "REJECT") ? "disabled-approve-btn" : ""}`} onClick={handleApproveGoal} disabled={approveRejectLoader || location?.state?.goalData?.status === "APPROVED" || location?.state?.goalData?.status === "REJECT"}>Approve</button>
                </div>
                   
                   <GoalsForm formId={location?.state?.goalId} employeeId={location?.state?.employeeId} pageMoodViewOrEdit={'view'} />

                </div>
                 
                 {
                    
                    // commentLoader ? 
                    // <div className="comments-card-layout">
                    //     <div className="d-flex">
                    //         <p className="comments-label">Comments</p>
                    //         <p className="comment-count">{allComments?.length}</p>
                    //     </div>
                    //     <Skeleton animation="wave" variant="rectangular" style={{height:"100px", marginBottom:"1rem"}} />
                    //     <Skeleton animation="wave" variant="rectangular" style={{height:"100px", marginBottom:"1rem"}} />
                    //     <Skeleton animation="wave" variant="rectangular" style={{height:"100px", marginBottom:"1rem"}} />
                    // </div>
                    // :                    
                    <Comments data={allComments?.filter(x => x?.id === location?.state?.goalId)[0]?.comments || []} addComment={addComment} id={location?.state?.goalId} addCommentLoader={addCommentLoader} comment={comment} setComment={setComment} setGetCommentPayload={setGetCommentPayload} />
                 }                 
               
            </div>

            {
                openDialog && 
                <DialogPopup openDialog={openDialog} setOpenDialog={setOpenDialog} redirect={false} path="">
                    
                    <div className="check-approved-popup-main">
                    <SlClose onClick={() => setOpenDialog(false)} />

                
                
                        <div className="table-heading-que" style={{margin: "0.7rem auto", textAlign:"center", fontSize:"1.25rem", fontWeight:"500"}}> 
                            {
                                dialogPopupFor === "approve" ? 
                                "Approving this Goal?"
                                :
                                "Rejecting this Goal?"
                            }
                        </div>

                        <TextField
                            id="outlined-multiline-flexible"
                            label= {dialogPopupFor === "approve" ? "Enter Reason for approval*" : "Enter Reason for rejection*"}
                            placeholder="Your text here"
                            multiline
                            rows={3}
                            autoFocus={true}
                            value={reason}
                            onChange={handleChangeReason}
                            sx={{ width: "100%", bgcolor: "var(--graybg)" }}
                        />

                        {
                            reasonErr &&
                            <FormHelperText sx={{ fontSize: "0.75rem", color: "red" }}>Please provide reason</FormHelperText>
                        }

                        <div className="button-outer-view-bottom">
                            <button className="approve-cancel-btn" onClick={() => setOpenDialog(false)} disabled={approveRejectLoader}>
                                Cancel
                            </button>
                            <button className={`approve-done-btn ${dialogPopupFor === "approve" ? "" : "reject-btn"}`} onClick={handleApproveGoals} disabled={approveRejectLoader}>
                                {
                                    approveRejectLoader ? 
                                    <CircularProgress size={27} />
                                    :
                                    "Submit"
                                }
                                
                            </button>
                        </div>
                        
                    </div>
                </DialogPopup>
            }

        </div>
    )
}

export default ViewGoal;