import { Accordion, AccordionDetails, AccordionSummary, Button, FormControl, InputLabel, MenuItem, Popover, Select, Stack, TextField, Checkbox, CircularProgress, ListItemText, Typography, Skeleton, LinearProgress, FormControlLabel } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { RxCross1 } from "react-icons/rx";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { MdCloudUpload, MdDelete, MdTabUnselected } from "react-icons/md";
import APIList from "../../../api";
import { useDispatch, useSelector } from "react-redux";
import { getListItemDescription, getObjectById, isEmptyNullUndefined, onlyAcceptWholeNumbers } from "../../utils/utils";
import { RiErrorWarningFill } from "react-icons/ri";
import { toast } from "react-toastify";
import DialogPopup from "../../common/Dialog";
import checkIcon from "../../../assets/images/popup-check.png";
import { useHistory, useLocation, useParams } from "react-router-dom/cjs/react-router-dom";
import { isRequiredFun, mapFormDataUtil, mapResponseErrorUtil, checkLengthOfArrayEqualOrNot, deepCloneObj, ResponseErrorConstructorFun, checkValidation, getResponseFormField, ResponseErrorConstructorObj, errorCodeMessage, isTheirStringLimit, checkIsStringUnderLimitContainer, checkIsStringUnderLimitTab, getLabelByValueRenderValue, getLabelByValue, hideIfPageViewMoodAndMultiSelect, showCharLeft } from "./goalFormUtilFun";
import { DndFormSkeleton } from "./goalFormFields";
import { IoDocumentTextOutline } from "react-icons/io5";

const GoalsForm = ({formId, employeeId, pageMoodViewOrEdit}) => {

    
    const history = useHistory();
    const location = useLocation();
console.log("location", location)
    const {id, isReplica} = useParams()
    const employeeDetails = useSelector((state) => state?.empData?.empData);
    const activeGoalCycle = useSelector((state) => state?.goalCycleReducer?.activeGoalCycle);

    const [vertualError, setVertualError] = useState(false);
    const [vertualPayload, setVertualPayload] = useState(false);
    const [formData, setFormData] = useState(false);
    const [isLoading, setIsLoading] = useState(false)

    const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
    const [submitLoader, setSubmitLoader] = useState(false);
    const [status, setStatus] = useState(false);
    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
    const [dropdownLoader, setDropdownLoader] = useState(false)
    const [dropdownData, setDropdownData] = useState(null)
    const [formDataForContainer, setFormDataForContainer] = useState(false)

    const [formType, setFormType] = useState(null);

    const [isUploadingFile, setIsUploadingFile] = useState(false);
    const [files, setFiles] = useState([]);
    const [filesLocal, setFilesLocal] = useState([]);
    const [loaderDeleteDoc, setLoaderDeleteDoc] = useState(false)
    
    const getEmployeeId = () => {
        if(employeeId) {
            return employeeId
        }
        else if(location?.state?.employeeId) {
            return location?.state?.employeeId
        }
        else {
            return employeeDetails?.id
        }
    }

    useEffect(() => {

        const fetchData = async () => {
            let tempFormType = null
            try {
                // if (!isEmptyNullUndefined(employeeDetails?.company?.id) && (!isEmptyNullUndefined(getEmployeeId()))) {
                if (!isEmptyNullUndefined(employeeDetails?.company?.id) && (!isEmptyNullUndefined(getEmployeeId()) && (!isEmptyNullUndefined(activeGoalCycle?.id)))) {
                    setIsLoading(() => true)
                    await getDropdownData();
                    tempFormType = await getFormType();
                    getFormData(tempFormType, activeGoalCycle);
                    // setIsLoading(() => false)
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                setIsLoading(() => false)
            }
        };
    
        fetchData(); // Call the async function
      
        
    }, [employeeDetails, activeGoalCycle]);

    ///////////document upload function/////
    
      const handleDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        // Handle dropped files here
        // console.log("Dropped files:", files);
    
        handleImageChange({ target: { files } });
      };

      const handleDeleteDocAPI = async (docId, index) => {
        setLoaderDeleteDoc(() => true)

        try {
          const response = await APIList.goalDoc_delete(docId);
          http://localhost:9093/api/goal-employee/delete/file/{id}  :: DELETE
          setTimeout(() => {
              if (response.status == 200) {
                console.log('ui function call')
                // deleteFromUiFun()
                handleDeleteDoc(index)
                setLoaderDeleteDoc(() => false)
              } else {
                console.log('catch',response.status)
                setLoaderDeleteDoc(() => false)
              }
          }, [500])

        } catch (error) {
            setLoaderDeleteDoc(() => false)
            toast.error(
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
                  &nbsp;&nbsp;{error?.message}
                </div>
            );
        }
    }
    
      const handleDeleteDoc = (index) => {
        // console.log(index);
    
        const tempFiles = structuredClone(files);
        tempFiles.splice(index, 1);
    
        // console.log(index);
    
        setFiles(tempFiles);
      };

      const handleDeleteDocLocal = (index) => {
        // console.log(index);
    
        const tempFiles = structuredClone(filesLocal);
        tempFiles.splice(index, 1);
    
        // console.log(index);
    
        setFilesLocal(tempFiles);
      };
    
      const openDoc = (index) => {
        const tempFiles = structuredClone(files);
        if (tempFiles?.[index]?.path) {
          window.open(tempFiles[index].path);
        }
      };

      const processToUpload = (file, localFileIndex, tempFilesLocal) => {

        console.log('file--------', file)
        const reader = new FileReader();

        let isUploadSuccessfull = true
    
        reader.onloadend = () => {
            const binaryData = reader.result; // This is the ArrayBuffer with the binary data
    
            // Create a Blob from the binary data
            const blob = new Blob([binaryData], { type: file.type });
    
            // Create a FormData object and append the blob with the correct field name
            const formData = new FormData();
            formData.append("file", blob, file.name);
    
            // Call the UploadFile function with the FormData
            isUploadSuccessfull = UploadFile(formData);
        };
    
        // Read the file as an ArrayBuffer
        reader.readAsArrayBuffer(file); // Make sure you have a valid file object

        tempFilesLocal.splice(localFileIndex, 1)

        if(isUploadSuccessfull) {
            setFilesLocal(() => tempFilesLocal)
        }
    };
    
    
    const handleImageChange = (event) => {
        const fileInput = event.target; // Access the file input element
        const file =
            event.target.files.length > 0 ? event.target.files[0] : undefined;
        // console.log('file',file);
        if (validateImage(file)) {
        //   UploadFile(file); direct upload


            let tempFilesLocal = structuredClone(filesLocal)
            tempFilesLocal.push(file)
            console.log('tempFilesLocal', tempFilesLocal)
            setFilesLocal(() => tempFilesLocal) // first store in local


        // Clear the file input after adding the file
        fileInput.value = ''; // This will clear the file input so it can be reused for a new file
        } else {
            console.log('handleImageChange reject file')
            // If validation fails, clear the file input
            fileInput.value = ''; 
        }
        };
        const validateImage = (file) => {
        if (!file) {
        //   setError("Please select an image.");
            toast.error(
            <div style={{ display: "flex", flexDirection: "row" }}>
                <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
                &nbsp;&nbsp;{"Please select valid doc."}
            </div>
            );
            return false;
        }
        if (file.size > 2097152) {
        //   setError("Selected image exceeds 2MB limit.");
            toast.error(
            <div style={{ display: "flex", flexDirection: "row" }}>
                <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
                &nbsp;&nbsp;{"Selected doc exceeds 2MB limit."}
            </div>
            );
            return false;
        }
        // const allowedTypes = ['image/jpeg', 'image/png'];
        // const allowedTypes = ['image/jpeg', 'image/png', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'text/csv'];

        // const allowedTypes = [
        //   "image/jpeg",
        //   "image/png",
        //   "application/pdf",
        //   "application/msword",
        //   "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        //   "application/vnd.ms-excel",
        //   "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        //   "application/vnd.ms-powerpoint",
        //   "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        //   "text/csv",
        //   "application/vnd.ms-excel", // Add XLS type
        // ];
        const allowedTypes = [
            // Image formats
            "image/jpeg",
            "image/png",
            
            // PDF
            "application/pdf",
            
            // Word documents
            "application/msword", // .doc
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // .docx
            
            // Excel sheets
            "application/vnd.ms-excel", // .xls
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
            
            // PowerPoint presentations
            "application/vnd.ms-powerpoint", // .ppt
            "application/vnd.openxmlformats-officedocument.presentationml.presentation", // .pptx
            
            // Text and CSV files
            "text/plain", // .txt
            "text/csv", // .csv
            
            // Rich Text Format
            "application/rtf", // .rtf
            
            // OpenDocument formats
            "application/vnd.oasis.opendocument.text", // .odt
            "application/vnd.oasis.opendocument.spreadsheet", // .ods
            "application/vnd.oasis.opendocument.presentation", // .odp
        ];
            
        if (!allowedTypes.includes(file.type)) {
        //   setError("Please select a JPEG or PNG image.");
            toast.error(
            <div style={{ display: "flex", flexDirection: "row" }}>
                <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
                &nbsp;&nbsp;{"Please select a valid Doc."}
            </div>
            );
            return false;
        }
        // setError(null);
        return true;
    };
    
    //   const UploadFile = async (file) => {
    //     setIsUploadingFile(() => true);
    //     const formData = new FormData();
    //     formData.append("file", file);
    
    //     const headers = {
    //       "Content-Type": "multipart/form-data",
    //     };
    
    //     await APIList.goalfileUpload(formData, headers)
    //       .then((res) => {
    //         let tempTotalDoc = structuredClone(files);
    //         tempTotalDoc.push({ ...res.data });
    
    //         // console.log("res.data", { ...res.data });
    //         // console.log("tempTotalDoc", tempTotalDoc);
    
    //         setFiles(tempTotalDoc);
    //         setIsUploadingFile(() => false);
    
    //         // setSelectedImage(res.data)
    //         // APIList.changeProfilePhote({
    //         //     "profilePhoto":{
    //         //         "id":res.data.id
    //         //     }
    //         // } ,employeeDetails.id)
    //         // .then((res)=>{
    //         //     setIsUploadingFile(() => false);
    //         //     toast.success('Profile photo updated successfully');
    //         //     setTimeout(() => {
    //         //         // window.location.reload();
    //         //         dispatch(EmployeeDataActions.EmployeeDataRequest());
    //         //     }, 1000);
    
    //         // }).catch((err)=>{
    //         //     toast.error(
    //         //         <div style={{display:"flex", flexDirection:"row"}}>
    //         //           <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
    //         //           &nbsp;&nbsp;{err?.title}
    //         //         </div>
    //         //     );
    //         //     setIsUploadingFile(() => false);
    //         // })
    //       })
    //       .catch((err) => {
    //         toast.error(
    //           <div style={{ display: "flex", flexDirection: "row" }}>
    //             <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
    //             &nbsp;&nbsp;{err?.title}
    //           </div>
    //         );
    //         setIsUploadingFile(() => false);
    //       });
    //   };
    //////////////////////////////////////////

    const UploadFile = async (formData) => { // updated by chatgpt 
        setIsUploadingFile(true); // Start the upload state

        let isUploadSuccessfull = false
    
        try {
            const headers = {
                "Accept": "application/json", // Adjust headers if necessary
            };
    
            // Make sure your API endpoint is correct
            const response = await APIList.goalfileUpload(formData, headers);
    
            // Handle the response appropriately
            const tempTotalDoc = structuredClone(files);
            tempTotalDoc.push({ ...response.data });
            // tempTotalDoc.push({ ...tempResponseData }); //with out id
            setFiles(tempTotalDoc);
    
            setIsUploadingFile(false); // Stop the upload state

            isUploadSuccessfull = true
        } catch (error) {
            // Handle errors appropriately
            toast.error(
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
                    &nbsp;&nbsp;{error?.title || "Upload failed."}
                </div>
            );
            setIsUploadingFile(false); // Stop the upload state

            isUploadSuccessfull = false
        }

        return isUploadSuccessfull
    };
    const goalEmployeeDelete = async (siblingContainerId, deleteFromUiFun) => {

       
        
        try {
          const response = await APIList.goalEmployee_delete(siblingContainerId);
          setTimeout(() => {
              if (response.status == 200) {
                console.log('ui function call')
                deleteFromUiFun()
              } else {
                console.log('catch',response.status)
              }
          }, [500])

          
        } catch (error) {
            
        
          
        }
    }

    const getDropdownData = async() => {
        try{
            setDropdownLoader(true);
            // /api/goal-employee/entity-dropdown/{companyId}/{loginEmployee}
            // const response = await APIList.getMyTeamDropdowns({
                const response = await APIList.getGoalFormDropdowns({
                // range: "My Team",                                                            // My Team Or My Org
                companyId: employeeDetails?.company?.id,
                managerId: getEmployeeId()
            })
            if (response.status === 200) {
                setDropdownData(response?.data);
                setDropdownLoader(false);
            }
        } catch(error) {
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                    <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                    &nbsp;&nbsp;{error?.title}
                </div>
            );
            setDropdownLoader(false);
        }
    
    }

    const getFormType = async () => {

        let tempFormType = null
        let payload = {

            companyId: employeeDetails.company.id,
            employeeId: getEmployeeId()
            // formType:"base",
            // isContainer:false    

        }
        
        try {
          const response = await APIList.goalEmployeeFormType(payload);
          if (response.status === 200) {
            setFormType(() => response.data)
            tempFormType = response.data
          } else {
            tempFormType = {
                containerFormTypes: [],
                formType: "BASE"
            }
          }
        } catch (error) {
            tempFormType = {
                containerFormTypes: [],
                formType: "BASE"
            }
        }

        return tempFormType


    };

    const getFormData = async (tempFormType, tempGoalCycle) => {

        let payloadBase = {
            companyId: employeeDetails.company.id,
            employeeId: getEmployeeId(),    
            status: 'DRAFT',
            goalCycleId: tempGoalCycle.id,
            ...tempFormType
        }

        if(!isEmptyNullUndefined(formId)) {
            payloadBase.id = formId
        } else if(!isEmptyNullUndefined(id)) {
            if(id === 'newform') {
                console.log('new form')
            } else {
                console.log('id form')
                payloadBase.id = id
                // payloadContainer.id = id
            }
        }

        setTimeout(async() => {
            try {
    
                const responseBase = await APIList.goalEmployee_employee_form(payloadBase);
                if (responseBase.status === 200) {
    
                    const {tempResponseData, tempgoalFormFieldForContainer, tempresponseFormContainerField} = await mapFormDataUtil(responseBase, setIsLoading)
                    let tempResponseError = await mapResponseErrorUtil(deepCloneObj(tempgoalFormFieldForContainer, setIsLoading))
    
                    // return
                    setVertualPayload(tempgoalFormFieldForContainer);
                    setFormData(tempResponseData);
                    setFormDataForContainer(tempgoalFormFieldForContainer);
                    // getGoalFormFieldForContainer = tempgoalFormFieldForContainer;
                   
                    // let tempResponseError = mapResponseErrorUtil(deepCloneObj(getGoalFormFieldForContainer))


                    if(!isEmptyNullUndefined(responseBase?.data?.goalEmployeeFiles)) {
                        setFiles(() => responseBase.data.goalEmployeeFiles)
                    }
                    setVertualError(() => tempResponseError)
                    setIsLoading(() => false)
    
                } else {
                    setIsLoading(() => false)
                }
    
    
            } catch (error) {
                setIsLoading(() => false)
            }
        }, [500])

        
        
    };

    const handleChangeVertualPayloadContainer = (
        tempVertualPayload,
        event,
        inputType,
        tabIndex,
        tabRowPosition,
        TabElement,

        tileIndex,
        TileElement,
        tileRowPosition,
        groupContainerI,
        groupContainerE,
        groupContainerRowPosition,
        tempVertualError

      ) => {

        if (inputType === "textField") {
            tempVertualPayload[tabIndex][groupContainerI][groupContainerRowPosition].container[tileIndex][tileRowPosition].response.stringResponse = event.target.value

            if(isTheirStringLimit(TileElement[tileRowPosition])) {
                checkIsStringUnderLimitContainer(TileElement[tileRowPosition], setVertualError, tempVertualPayload, tempVertualError, 'tile',  tabIndex, tabRowPosition, groupContainerI, groupContainerRowPosition, tileIndex, tileRowPosition)
                setVertualPayload(() => tempVertualPayload)
                return
            }
        } else if(inputType === 'selectField') {
            if((TileElement[tileRowPosition].isSingleSelect === 'true') || (TileElement[tileRowPosition].isSingleSelect === true)) {
                let tempValue = []
                tempValue.push(event.target.value)
                tempVertualPayload[tabIndex][groupContainerI][groupContainerRowPosition].container[tileIndex][tileRowPosition].response.selectResponse = tempValue;
            } else {
                tempVertualPayload[tabIndex][groupContainerI][groupContainerRowPosition].container[tileIndex][tileRowPosition].response.selectResponse = event.target.value;
            }
        } else if (inputType === 'dateField') {
            tempVertualPayload[tabIndex][groupContainerI][groupContainerRowPosition].container[tileIndex][tileRowPosition].response.dateResponse = event;
        } else if (inputType === 'numberField') {
            tempVertualPayload[tabIndex][groupContainerI][groupContainerRowPosition].container[tileIndex][tileRowPosition].response.numberResponse = onlyAcceptWholeNumbers(event.target.value) ;
        }

        // reset error of container field
        const responseError = new ResponseErrorConstructorFun(false, '')
        // const responseError = deepCloneObj(ResponseErrorConstructorObj(false, ''))
        tempVertualError[tabIndex][groupContainerI][groupContainerRowPosition].container[tileIndex][tileRowPosition].responseError = responseError;
        setVertualPayload(() => tempVertualPayload);
        setVertualError(() => tempVertualError)
      }

      const handleChangeVertualPayload = (
        tempVertualPayload,
        event,
        inputType,
        tabIndex,
        rowPosition,
        TabElement,
        tempVertualError
      ) => {

        if (inputType === "textField") {
            tempVertualPayload[tabIndex][rowPosition].response.stringResponse = event.target.value;

            if(isTheirStringLimit(TabElement[rowPosition])) {
                checkIsStringUnderLimitTab(TabElement[rowPosition], setVertualError, tempVertualPayload, tempVertualError, 'tab',  tabIndex, rowPosition)
                setVertualPayload(() => tempVertualPayload)
                return
            }
            
           


            
        } else if(inputType === 'selectField') {
            if((TabElement[rowPosition].isSingleSelect === 'true') || (TabElement[rowPosition].isSingleSelect === true)) {
                let tempValue = []
                tempValue.push(event.target.value)
                tempVertualPayload[tabIndex][rowPosition].response.selectResponse = tempValue;
            } else {
                tempVertualPayload[tabIndex][rowPosition].response.selectResponse = event.target.value;
            }
        } else if (inputType === 'dateField') {
            tempVertualPayload[tabIndex][rowPosition].response.dateResponse = event;
        } else if (inputType === 'numberField') {
            tempVertualPayload[tabIndex][rowPosition].response.numberResponse = onlyAcceptWholeNumbers(event.target.value);
        }

        // reset field error
        const responseError = new ResponseErrorConstructorFun(false, '')
        // const responseError = deepCloneObj(ResponseErrorConstructorObj(false, ''))
        tempVertualError[tabIndex][rowPosition].responseError = responseError

        setVertualPayload(() => tempVertualPayload)
        setVertualError(() => tempVertualError)

      }

      const styleSubmit = () => {
        if(status === 'ACTIVE') {
          return ({
            pointerEvents: 'none',
            opacity: '50%',
            transition: 'opacity 0.3s',
            visibility: 'hidden',
          })
        } else {
          if(isSubmitDisabled || submitLoader) {
            return ({
              pointerEvents: 'none',
              opacity: '50%',
              transition: 'opacity 0.3s'
            })
          } else {
            return ({
              pointerEvents: 'auto',
              opacity: '100%',
              transition: 'opacity 0.3s'
            })
          }
        }
    }
    
      const styleSaveAsDraft = () => {
        if(status === 'ACTIVE') {
          return ({
            pointerEvents: 'none',
            opacity: '50%',
            transition: 'opacity 0.3s',
            visibility: 'hidden',
          })
        } else {
          if(submitLoader) {
            return ({
              pointerEvents: 'none',
              opacity: '50%',
              transition: 'opacity 0.3s'
            })
          } else {
            return ({
              pointerEvents: 'auto',
              opacity: '100%',
              transition: 'opacity 0.3s'
            })
          }
        }
      }

      const handleValidate = (isDraft) => {

        const {isValid, tempVertualError} = checkValidation(vertualPayload, vertualError)

        if (isDraft) {
            setShowConfirmationPopup('DRAFT'); // status draft
        } else if (isValid) {
            setShowConfirmationPopup('SUBMIT'); // status SUBMIT
        } else {
            // set form error
            setVertualError(tempVertualError);
            toast.error(
                <div style={{ display: "flex", flexDirection: "row" }}>
                <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
                &nbsp;&nbsp;{"Please enter all mandatory field"}
                </div>
            );
        }

      }

      const handleSubmitFeedback = (status) => {
        setShowConfirmationPopup(false);
        if (!isEmptyNullUndefined(formData.id)) {
          //  'update calls -------------'
        //   handleUpdate('ACTIVE');
        handleSubmit(status);
        } else {
          // 'submit calls--------------'
          handleSubmit(status);
        }
      }
console.log("formData", formData)
      const handleSubmit = (status, submitType) => {
        let { tempResponseFormField, tempResponseFormContainerField } = getResponseFormField(deepCloneObj(vertualPayload))
        // return
        let tempFiles = structuredClone(files);
        let tempFilesTwo = []
        // let tempFilesTwo = 
        // if(!isEmptyNullUndefined(tempFiles) && tempFiles.length > 0) {
        //     tempFiles.map((e) => {
        //         let clone = { ...e }; // Shallow clone the object
        //         delete clone.id; // Delete the 'id' property
        //         // return clone; // Return the modified object
        //         tempFilesTwo.push(clone)
        //     });
        // }
        let payload = {
            goalEmployeeFiles:files,
            // goalEmployeeFiles:tempFilesTwo,
            companyId: employeeDetails.company.id,
            employeeId: getEmployeeId(),   
            // formType:formType?.baseForm || "BASE",
            // containerFormTypes: ["Milestone"],
            // formType: "BASE",
            // containerFormTypes: [],
            isContainer:true,
            responseFormField: tempResponseFormField,
            responseFormContainerField: tempResponseFormContainerField,
            updatedBy: location?.state?.isManagerEdit ? "MANAGER" : "EMPLOYEE",
            status: formData?.status !== 'DRAFT' ? formData?.status : status,
            ...formType

            

            // id: "ad6d186e-b972-4529-beb7-d36e1307b1ea",
        }

        if(isReplica === 'true' || isReplica === true) {

        } else {
            if(!isEmptyNullUndefined(formData.id)) {
                payload.id = formData.id
            }
        }
    
        setSubmitLoader(true);
        APIList.post_goalEmployee_employee_form(payload)
          .then((res) => {
            // if((status == 'ACTIVE') || (submitType == 'submitAsDraft')) {
            //   setOpenDialog(true);
            // } else {
            //   setFormData(() => res.data)
            // }
            // setSubmitLoader(false);
            // setStatus(res.data.status)
            // setStatusForPost(res.data.status)
            // history.push({pathname:`/manager-feedBack/${emp?.employeeId}/no`, state: {feedbackStatus: emp?.feedbackstatus, aid: emp?.appraisalId, employeeId:emp?.employeeId}}); // at end /isAppreasalSubmited(yes/no)
            if(location?.state?.isManagerEdit) {
                history.push({pathname:`/ViewGoalsRequest`}); // at end /isAppreasalSubmited(yes/no)
            } else {
                history.push({pathname:`/employee-goals`, state: {action: status === "DRAFT" ? "draft" : "submit"}}); // at end /isAppreasalSubmited(yes/no)
            }
          })
          .catch((err) => {
            toast.error(
              <div style={{ display: "flex", flexDirection: "row" }}>
                <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
                &nbsp;&nbsp;{err?.message}
              </div>
            );
            setSubmitLoader(false);
          });
        // setSubmitLoader(false);
        setIsSubmitDisabled(false)
      };

      const handleAddMoreContainer = (
        TabElement, 
        tabIndex,
        tempFormDataForContainer, //clone
        tempVertualPayload, //clone
        tempVertualError, //clone
        tempFormData, //clone
      ) => {
        

          
          // ==================
            let tempTabElement = deepCloneObj(TabElement)
            const initialResponseError = new ResponseErrorConstructorFun();
            // const initialResponseError = deepCloneObj(ResponseErrorConstructorObj())
            
            tempTabElement[0].container.map((conFieldE, conFieldI) => {

                conFieldE[0].responseError = initialResponseError

                if(conFieldE[1]){
                    conFieldE[1].responseError = initialResponseError
                }

                return conFieldE
            })

        //   let TabElementError = tempTabElement.responseError = initialResponseError

        // ================

        tempVertualPayload[tabIndex].push(TabElement)
        tempVertualError[tabIndex].push(tempTabElement)
        tempFormDataForContainer[tabIndex].push(TabElement)

        setVertualPayload(() => tempVertualPayload)
        setVertualError(() => tempVertualError)
        setFormDataForContainer(() => tempFormDataForContainer)
      }

      const handleDeleteContainer = (
        TabElement, 
        tabIndex,
        groupContainerE,
        groupContainerI,
        tempFormDataForContainer, //clone
        tempVertualPayload, //clone
        tempvertualError, //clone
        tempFormData, //clone
      ) => {

        tempVertualPayload[tabIndex].splice(groupContainerI, 1)
        tempvertualError[tabIndex].splice(groupContainerI, 1)
        tempFormDataForContainer[tabIndex].splice(groupContainerI, 1)

        // const deleteSiblingContainerFromUi = ({tempVertualPayload, tempvertualError, tempFormDataForContainer}) => {
        const deleteSiblingContainerFromUi = ({tempVertualPayload, tempvertualError, tempFormDataForContainer}) => {
            setVertualPayload(() => tempVertualPayload)
            setVertualError(() => tempvertualError)
            setFormDataForContainer(() => tempFormDataForContainer)
        }

        if(isEmptyNullUndefined(groupContainerE[0].id)) {
            setVertualPayload(() => tempVertualPayload)
            setVertualError(() => tempvertualError)
            setFormDataForContainer(() => tempFormDataForContainer)
        } else {
            goalEmployeeDelete(groupContainerE[0].id, (() => deleteSiblingContainerFromUi({tempVertualPayload, tempvertualError, tempFormDataForContainer})), )
        }
      }

      const randerDynamicTileElement = (TileElement, tileIndex, TabElement, tabIndex, groupContainerE,groupContainerI) => {
          

        if(isEmptyNullUndefined(TileElement) || isEmptyNullUndefined(tileIndex) || isEmptyNullUndefined(TabElement) || isEmptyNullUndefined(tabIndex) || isEmptyNullUndefined(groupContainerE) || isEmptyNullUndefined(groupContainerI) || !checkLengthOfArrayEqualOrNot({vertualPayloadTile: vertualPayload[tabIndex], formDataForContainerTile: formDataForContainer[tabIndex], vertualErrorTile: vertualError[tabIndex]})) {
            return
        } 


        if (TileElement && TileElement?.length > 0) {
            return <div key={tileIndex}>
                        


                                        {((TileElement[0].isMultiField === true) || (TileElement[0].isMultiField === 'true')) ? (
                        

                                            <div className="field-row">
                                                <div className="row-half">
                                                    {(() => {
                                                        try {
                                                            return (
                                                                <>
                                                                    <div>
                                                                        {(TileElement[0].type === "text") && (
                                                                            <div className="text-field">

                                                                                <div className="field-label" >
                                                                                    {TileElement[0].label}
                                                                                </div>
                                                                                <TextField
                                                                                    disabled={!TileElement[0].isEditable}
                                                                                    name={TileElement[0].name}
                                                                                    value={vertualPayload[tabIndex][groupContainerI][0].container[tileIndex][0].response.stringResponse || ''}
                                                                                    onChange={(event) =>
                                                                                        handleChangeVertualPayloadContainer(
                                                                                            deepCloneObj(vertualPayload),
                                                                                            event,
                                                                                            "textField",
                                                                                            tabIndex,
                                                                                            0, //tab row position
                                                                                            TabElement,
                                                                                            tileIndex,
                                                                                            TileElement,
                                                                                            0, //tile row position
                                                                                            groupContainerI,
                                                                                            groupContainerE,
                                                                                            0, //groupContainer row position
                                                                                            deepCloneObj(vertualError)


                                                                                        )
                                                                                    }
                                                                                    multiline={!(pageMoodViewOrEdit === 'view') && (TileElement[0].isMultiTextFieldRow ?? false)}
                                                                                    rows={TileElement[0].textFieldRowSize ?? 1}
                                                                                    id="outlined-multiline-static"
                                                                                    variant="outlined"
                                                                                    size="small"
                                                                                    sx={{
                                                                                        width: '100%',
                                                                                        mt: '6px',
                                                                                        "& fieldset": {
                                                                                            borderRadius: '8px',                              
                                                                                            },
                                                                                        "& fieldset":isRequiredFun(TileElement[0].required)    
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                        {(TileElement[0].type === "number") && (
                                                                            <div className="number-field">

                                                                                <div className="field-label" >
                                                                                    {TileElement[0].label}
                                                                                </div>
                                                                                <TextField
                                                                                disabled={!TileElement[0].isEditable}
                                                                                    name={TileElement[0].name}
                                                                                    value={vertualPayload[tabIndex][groupContainerI][0].container[tileIndex][0].response.numberResponse || ''}
                                                                                    onChange={(event) =>
                                                                                        handleChangeVertualPayloadContainer(
                                                                                            deepCloneObj(vertualPayload),
                                                                                            event,
                                                                                            "numberField",
                                                                                            tabIndex,
                                                                                            0, //tab row position
                                                                                            TabElement,
                                                                                            tileIndex,
                                                                                            TileElement,
                                                                                            0, //tile row position
                                                                                            groupContainerI,
                                                                                            groupContainerE,
                                                                                            0, //groupContainer row position
                                                                                            deepCloneObj(vertualError)


                                                                                        )
                                                                                    }
                                                                                    multiline={!(pageMoodViewOrEdit === 'view') && (TileElement[0].isMultiTextFieldRow ?? false)}
                                                                                    rows={TileElement[0].textFieldRowSize ?? 1}
                                                                                    id="outlined-multiline-static"
                                                                                    variant="outlined"
                                                                                    size="small"
                                                                                    sx={{
                                                                                        width: '100%',
                                                                                        mt: '6px',
                                                                                        "& fieldset": {
                                                                                            borderRadius: '8px',                              
                                                                                        },
                                                                                        "& fieldset":isRequiredFun(TileElement[0].required)
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                        {(TileElement[0].type === "select") && (
                                                                            <div className="select-field">
                                                                            
                                                                            <div className="field-label" >
                                                                                {TileElement[0].label}
                                                                            </div>
                                                                            {hideIfPageViewMoodAndMultiSelect(pageMoodViewOrEdit, TileElement[0]) && <div>
                                                                                <FormControl
                                                                                sx={{width: {md: '100%', sm: '100%'}}}
                                                                                >
                                                                                <Select
                                                                                disabled={!TileElement[0].isEditable}
                                                                                    value={vertualPayload[tabIndex][groupContainerI][0].container[tileIndex][0].response.selectResponse || []}
                                                                                    onChange={(event) =>
                                                                                        handleChangeVertualPayloadContainer(
                                                                                            deepCloneObj(vertualPayload),
                                                                                            event,
                                                                                            "selectField",
                                                                                            tabIndex,
                                                                                            0, //tab row position
                                                                                            TabElement,
                                                                                            tileIndex,
                                                                                            TileElement,
                                                                                            0, //tile row position
                                                                                            groupContainerI,
                                                                                            groupContainerE,
                                                                                            0, //groupContainer row position
                                                                                            deepCloneObj(vertualError)


                                                                                        )
                                                                                    }
                                                                                    renderValue={(selected) =>
                                                                                        {
                                                                                            if((TileElement[0].isSelectMenuDynamic === 'true') || (TileElement[0].isSelectMenuDynamic === true)) {
                                                                                              return getListItemDescription(selected, dropdownData?.[TileElement[0].selectMenuDynamicType], 'name')
                                                                                            } else {
																																															return getLabelByValueRenderValue(TileElement[0].options, selected)
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                    name={TileElement[0].name}
                                                                                    
                                                                                    sx={{
                                                                                        width: '100%',
                                                                                        mt: '6px',
                                                                                        "& fieldset": { 
                                                                                            borderRadius: '8px',   
                                                                                        },
                                                                                        "& fieldset":isRequiredFun(TileElement[0].required)
                                                                                    }}
                                                                                    size="small"
                                                                                    labelId='demo-simple-select-label'
                                                                                    id='demo-simple-select'
                                                                                    multiple={((TileElement[0].isSingleSelect === 'true') || (TileElement[0].isSingleSelect === true)) ? false : true}
                                                                                >
                                                                                    {(() => {
                                                                                        try{

                                                                                            return (
                                                                                                (TileElement[0].isSelectMenuDynamic === 'true') || (TileElement[0].isSelectMenuDynamic === true)) ? (
                                                                                                    dropdownData?.[TileElement[0].selectMenuDynamicType].map((e,i) => {
                                                                                                        return <MenuItem key={e.id + "-" + i} value={e.id}><ListItemText primary={e.name} /></MenuItem>;
                                                                                                    })
                                                                                                
                                                                                                ) : (
                                                                                                    TileElement[0]?.options?.map(
                                                                                                        (e, i) => {
                                                                                                        return <MenuItem key={e.value + i} value={e.value}><ListItemText primary={e.label} /></MenuItem>;
                                                                                                        },
                                                                                                    )
                                                                                                )
                                                                                        } catch(error){
                                                                                            console.log(`${errorCodeMessage} 1d3513fv5dv1 left field TileElement ${tileIndex}` )

                                                                                            toast.error(
                                                                                                <div style={{display:"flex", flexDirection:"row"}}>
                                                                                                    <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                                                                                                    &nbsp;&nbsp;{error.message}
                                                                                                </div>
                                                                                            );
                                                                                        }
                                                                                    })()}
                                                                                </Select>
                                                                                </FormControl>
                                                                            </div>}
                                                                            {(() => {
                                                                                try {
                                                                                    return !((TileElement[0].isSingleSelect === 'true') || (TileElement[0].isSingleSelect === true)) && (vertualPayload[tabIndex][groupContainerI][0].container[tileIndex][0].response.selectResponse) && (vertualPayload[tabIndex][groupContainerI][0].container[tileIndex][0].response.selectResponse.length > 0) && (
                                                                                        <div className="selected-menu">
                                                                                            {vertualPayload[tabIndex][groupContainerI][0].container[tileIndex][0].response.selectResponse.map((e,i) => 
                                                                                                <div key={i} className="menu-item-container">
                                                                                                    <div className="title">
                                                                                                        {!((TileElement[0].isSelectMenuDynamic === 'true') || (TileElement[0].isSelectMenuDynamic === true)) ? (
                                                                                                           getLabelByValue(TileElement[0], e)
        
                                                                                                        ) : (
                                                                                                            <>{(getObjectById(dropdownData[TileElement[0].selectMenuDynamicType], e))?.name}</>
                                                                                                        )}
                                                                                                    </div>
                                                                                                    <div className="delete">
                                                                                                        <RxCross1 />
                                                                                                    </div>
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                    ) 
                                                                                } catch(error) {
                                                                                    console.log(`${errorCodeMessage} dg5d6h4d4gr5g4fh8dg8 left field TileElement ${tileIndex}` )

                                                                                    toast.error(
                                                                                        <div style={{display:"flex", flexDirection:"row"}}>
                                                                                            <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                                                                                            &nbsp;&nbsp;{error.message}
                                                                                        </div>
                                                                                    );
                                                                                }
                                                                            })()}
                                                                            
                                                                        </div>
                                                                        )}
                                                                        {(TileElement[0].type === "date") && (
                                                                            <div className="date-field">
                                                                                    
                                                                                <div className="field-label" >
                                                                                    {TileElement[0].label}
                                                                                </div>
                                                                                <div>
                                                                                    <FormControl
                                                                                    sx={{width: {md: '100%', sm: '100%'}}}
                                                                                    >
                                                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                                            <DesktopDatePicker
                                                                                                disabled={!TileElement[0].isEditable}
                                                                                                name={TileElement[0].name}
                                                                                                value={vertualPayload[tabIndex][groupContainerI][0].container[tileIndex][0].response.dateResponse}
                                                                                                onChange={(event) =>
                                                                                                    handleChangeVertualPayloadContainer(
                                                                                                        deepCloneObj(vertualPayload),
                                                                                                        event,
                                                                                                        "dateField",
                                                                                                        tabIndex,
                                                                                                        0, //tab row position
                                                                                                        TabElement,
                                                                                                        tileIndex,
                                                                                                        TileElement,
                                                                                                        0, //tile row position
                                                                                                        groupContainerI,
                                                                                                        groupContainerE,
                                                                                                        0, //groupContainer row position
                                                                                                        deepCloneObj(vertualError)
                                                
                                                
                                                                                                    )
                                                                                                }
                                                                                                
                                                                                                sx={{
                                                                                                    width: '100%',
                                                                                                    borderRadius: '8px',
                                                                                                    "& .MuiOutlinedInput-root": {
                                                                                                        borderRadius: '8px', 
                                                                                                        border: "1px solid #F9F9F9"
                                                                                                    }
                                                                                                }}
                                                                                                renderInput={(params) => (
                                                                                                    <TextField
                                                                                                    size={"small"}
                                                                                                    variant="outlined"
                                                                                                    sx={{
                                                                                                        pointerEvents: 'none',
                                                                                                        '& .MuiOutlinedInput-root': {
                                                                                                        'button': {
                                                                                                            pointerEvents: 'all',
                                                                                                        }},
                                                                                                        mt: '6px',
                                                                                                        width: "100%",
                                                                                                        "& fieldset": {
                                                                                                        border: "1px solid #dedede",
                                                                                                        },
                                                                                                        "& .css-k4qjio-MuiFormHelperText-root": {
                                                                                                        backgroundColor: "#ffffff",
                                                                                                        margin: "0px",
                                                                                                        paddingLeft: "0.5rem",
                                                                                                        },
                                                                                                        "& .MuiOutlinedInput-root": {
                                                                                                            // border: "1px solid #F9F9F9",
                                                                                                            // borderColor: 'rgba(0, 0, 0, 0.23)',
                                                                                                            // borderRadius: '8px',
                                                                                                            'button': {
                                                                                                                pointerEvents: 'all',
                                                                                                            }
                                                                                                        },
                                                                                                        "& fieldset":isRequiredFun(TileElement[0].required)
                                                                                                    }}

                                                                                                    {...params}
                                                                                                />
                                                                                                )}
                                                                                            />                     
                                                                                        </LocalizationProvider>
                                                                                    </FormControl>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                        {(TileElement[0].type === "empty") && (
                                                                            <div className="empty-field">
                                                                                    
                                                                                
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                    <div className=" d-flex justify-content-between">
                                                                        <div className="field-error-message">
                                                                            {
                                                                                (!Array.isArray(vertualError[tabIndex][groupContainerI][0].container[tileIndex][0])) && vertualError[tabIndex][groupContainerI][0].container[tileIndex][0].responseError.isError && (
                                                                                    <Typography>{vertualError[tabIndex][groupContainerI][0].container[tileIndex][0].responseError.errorMessage}</Typography>
                                                                                )
                                                                            }
                                                                            
                                                                        </div>
                                                                        {!(pageMoodViewOrEdit === 'view') && isTheirStringLimit(TileElement[0]) && <div className="cha-left-indicator">
                                                                            {showCharLeft(TileElement[0], vertualPayload[tabIndex][groupContainerI][0].container[tileIndex][0].response?.stringResponse?.length)}
                                                                        </div>}

                                                                    </div>
                                                                </>
                                                            )
                                                        } catch(error) {
                                                            console.log(`${errorCodeMessage} 4864s54f554df, left field TileElement ${tileIndex}` )

                                                            toast.error(
                                                                <div style={{display:"flex", flexDirection:"row"}}>
                                                                    <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                                                                    &nbsp;&nbsp;{error.message}
                                                                </div>
                                                            );
                                                        }
                                                    })()}

                                                    
                                                    

                                                </div>
                                                <div className="row-half">
                                                    {(() => {
                                                        try{
                                                            return (
                                                                <>
                                                                    <div>
                                                                        {(TileElement[1].type === "text") && (
                                                                            <div className="text-field">
        
                                                                                <div className="field-label" >
                                                                                    {TileElement[1].label}
                                                                                </div>
                                                                                <TextField
                                                                                disabled={!TileElement[1].isEditable}
                                                                                    name={TileElement[1].name}
                                                                                    value={vertualPayload[tabIndex][groupContainerI][0].container[tileIndex][1].response.stringResponse || ''}
                                                                                    onChange={(event) =>
                                                                                        handleChangeVertualPayloadContainer(
                                                                                            deepCloneObj(vertualPayload),
                                                                                            event,
                                                                                            "textField",
                                                                                            tabIndex,
                                                                                            0, //tab row position
                                                                                            TabElement,
                                                                                            tileIndex,
                                                                                            TileElement,
                                                                                            1, //tile row position
                                                                                            groupContainerI,
                                                                                            groupContainerE,
                                                                                            0, //groupContainer row position
                                                                                            deepCloneObj(vertualError)
        
        
                                                                                        )
                                                                                    }
                                                                                    multiline={!(pageMoodViewOrEdit === 'view') && (TileElement[1].isMultiTextFieldRow ?? false)}
                                                                                    rows={TileElement[1].textFieldRowSize ?? 1}
                                                                                    id="outlined-multiline-static"
                                                                                    variant="outlined"
                                                                                    size="small"
                                                                                    sx={{
                                                                                        width: '100%',
                                                                                        mt: '6px',
                                                                                        "& fieldset": {
                                                                                            borderRadius: '8px',                              
                                                                                        },
                                                                                        "& fieldset":isRequiredFun(TileElement[1].required)
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                        {(TileElement[1].type === "number") && (
                                                                            <div className="number-field">
        
                                                                                <div className="field-label" >
                                                                                    {TileElement[1].label}
                                                                                </div>
                                                                                <TextField
                                                                                disabled={!TileElement[1].isEditable}
                                                                                    name={TileElement[1].name}
                                                                                    value={vertualPayload[tabIndex][groupContainerI][0].container[tileIndex][1].response.numberResponse || ''}
                                                                                    onChange={(event) =>
                                                                                        handleChangeVertualPayloadContainer(
                                                                                            deepCloneObj(vertualPayload),
                                                                                            event,
                                                                                            "numberField",
                                                                                            tabIndex,
                                                                                            0, //tab row position
                                                                                            TabElement,
                                                                                            tileIndex,
                                                                                            TileElement,
                                                                                            1, //tile row position
                                                                                            groupContainerI,
                                                                                            groupContainerE,
                                                                                            0, //groupContainer row position
                                                                                            deepCloneObj(vertualError)
        
        
                                                                                        )
                                                                                    }
                                                                                    multiline={!(pageMoodViewOrEdit === 'view') && (TileElement[1].isMultiTextFieldRow ?? false)}
                                                                                    rows={TileElement[1].textFieldRowSize ?? 1}
                                                                                    id="outlined-multiline-static"
                                                                                    variant="outlined"
                                                                                    size="small"
                                                                                    sx={{
                                                                                        width: '100%',
                                                                                        mt: '6px',
                                                                                        "& fieldset": {
                                                                                            borderRadius: '8px',                              
                                                                                        },
                                                                                        "& fieldset":isRequiredFun(TileElement[1].required)
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                        {(TileElement[1].type === "select") && (
                                                                            <div className="select-field">
                                                                            
                                                                                <div className="field-label" >
                                                                                    {TileElement[1].label}
                                                                                </div>
                                                                                {hideIfPageViewMoodAndMultiSelect(pageMoodViewOrEdit, TileElement[1]) && <div>
                                                                                    <FormControl
                                                                                    sx={{width: {md: '100%', sm: '100%'}}}
                                                                                    >
                                                                                    <Select
                                                                                    disabled={!TileElement[1].isEditable}
                                                                                        name={TileElement[1].name}
                                                                                        value={vertualPayload[tabIndex][groupContainerI][0].container[tileIndex][1].response.selectResponse || []}
                                                                                        onChange={(event) =>
                                                                                            handleChangeVertualPayloadContainer(
                                                                                                deepCloneObj(vertualPayload),
                                                                                                event,
                                                                                                "selectField",
                                                                                                tabIndex,
                                                                                                0, //tab row position
                                                                                                TabElement,
                                                                                                tileIndex,
                                                                                                TileElement,
                                                                                                1, //tile row position
                                                                                                groupContainerI,
                                                                                                groupContainerE,
                                                                                                0, //groupContainer row position
                                                                                                deepCloneObj(vertualError)
        
        
                                                                                            )
                                                                                        }
                                                                                        renderValue={(selected) =>
                                                                                            {
                                                                                                if((TileElement[1].isSelectMenuDynamic === 'true') || (TileElement[1].isSelectMenuDynamic === true)) {
                                                                                                  return getListItemDescription(selected, dropdownData?.[TileElement[1].selectMenuDynamicType], 'name')
                                                                                                } else {
																																																	return getLabelByValueRenderValue(TileElement[1].options, selected)
                                                                                                }
                                                                                            }
                                                                                        }
        
                                                                                        sx={{
                                                                                            width: '100%',
                                                                                            mt: '6px',
                                                                                            "& fieldset": { 
                                                                                                borderRadius: '8px',    
                                                                                            },
                                                                                            "& fieldset":isRequiredFun(TileElement[1].required)
                                                                                        }}
                                                                                        size="small"
                                                                                        labelId='demo-simple-select-label'
                                                                                        id='demo-simple-select'
                                                                                        multiple={((TileElement[1].isSingleSelect === 'true') || (TileElement[1].isSingleSelect === true)) ? false : true}
                                                                                    >
                                                                                        {(() => {
                                                                                            try{
                                                                                                return (
                                                                                                    (TileElement[1].isSelectMenuDynamic === 'true') || (TileElement[1].isSelectMenuDynamic === true)) ? (
                                                                                                        dropdownData?.[TileElement[1].selectMenuDynamicType].map((e,i) => {
                                                                                                            return <MenuItem key={e.id + "-" + i} value={e.id}><ListItemText primary={e.name} /></MenuItem>;
                                                                                                        })
                                                                                                    
                                                                                                    ) : (
                                                                                                        TileElement[1]?.options?.map(
                                                                                                            (e, i) => {
                                                                                                            return <MenuItem key={e.value + i} value={e.value}><ListItemText primary={e.label} /></MenuItem>;
                                                                                                            },
                                                                                                        )
                                                                                                    )
                                                                                            } catch(error) {
                                                                                                console.log(`${errorCodeMessage}d51b6fd4b6f51b6f, right field TileElement ${tileIndex}` )

                                                                                                toast.error(
                                                                                                    <div style={{display:"flex", flexDirection:"row"}}>
                                                                                                        <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                                                                                                        &nbsp;&nbsp;{error.message}
                                                                                                    </div>
                                                                                                );
                                                                                            }
                                                                                        })()}
                                                                                    </Select>
                                                                                    </FormControl>
                                                                                </div>}
                                                                                {(() => {
                                                                                    try {
                                                                                        return !((TileElement[1].isSingleSelect === 'true') || (TileElement[1].isSingleSelect === true)) && (vertualPayload[tabIndex][groupContainerI][0].container[tileIndex][1].response.selectResponse) && (vertualPayload[tabIndex][groupContainerI][0].container[tileIndex][1].response.selectResponse.length > 0) && (
                                                                                            <div className="selected-menu">
                                                                                                {vertualPayload[tabIndex][groupContainerI][0].container[tileIndex][1].response.selectResponse.map((e,i) => 
                                                                                                    <div className="menu-item-container">
                                                                                                        <div className="title">
                                                                                                            {!((TileElement[1].isSelectMenuDynamic === 'true') || (TileElement[1].isSelectMenuDynamic === true)) ? (
                                                                                                                getLabelByValue(TileElement[1], e)
                                                                                                            ) : (
                                                                                                                <>{(getObjectById(dropdownData[TileElement[1].selectMenuDynamicType], e))?.name}</>
                                                                                                            )}
                                                                                                        </div>
                                                                                                        <div className="delete">
                                                                                                            <RxCross1 />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )}
                                                                                            </div>
                                                                                        ) 
                                                                                    } catch(error) {
                                                                                        console.log(`${errorCodeMessage}dgdgdgr454g5, right field TileElement ${tileIndex}` )

                                                                                        toast.error(
                                                                                            <div style={{display:"flex", flexDirection:"row"}}>
                                                                                                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                                                                                                &nbsp;&nbsp;{error.message}
                                                                                            </div>
                                                                                        );
                                                                                    }
                                                                                })()}
                                                                            </div>
                                                                        )}
                                                                        {(TileElement[1].type === "date") && (
                                                                            <div className="date-field">
                                                                                    
                                                                                <div className="field-label" >
                                                                                    {TileElement[1].label}
                                                                                </div>
                                                                                <div>
                                                                                    <FormControl
                                                                                    sx={{width: {md: '100%', sm: '100%'}}}
                                                                                    >
                                                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                                            <DesktopDatePicker
                                                                                    disabled={!TileElement[1].isEditable}
                                                                                                name={TileElement[1].name}
                                                                                                value={vertualPayload[tabIndex][groupContainerI][0].container[tileIndex][1].response.dateResponse}
                                                                                                onChange={(event) =>
                                                                                                    handleChangeVertualPayloadContainer(
                                                                                                        deepCloneObj(vertualPayload),
                                                                                                        event,
                                                                                                        "dateField",
                                                                                                        tabIndex,
                                                                                                        0, //tab row position
                                                                                                        TabElement,
                                                                                                        tileIndex,
                                                                                                        TileElement,
                                                                                                        1, //tile row position
                                                                                                        groupContainerI,
                                                                                                        groupContainerE,
                                                                                                        0, //groupContainer row position
                                                                                                        deepCloneObj(vertualError)
                                                
                                                
                                                                                                    )
                                                                                                }
                                                                                                
                                                                                                sx={{
                                                                                                    width: '100%',
                                                                                                    borderRadius: '8px',
                                                                                                    "& .MuiOutlinedInput-root": {
                                                                                                        borderRadius: '8px', 
                                                                                                        border: "1px solid #F9F9F9"
                                                                                                    }
                                                                                                }}
                                                                                                renderInput={(params) => (
                                                                                                    <TextField
                                                                                                    size={"small"}
                                                                                                    variant="outlined"
                                                                                                    sx={{
                                                                                                        pointerEvents: 'none',
                                                                                                        '& .MuiOutlinedInput-root': {
                                                                                                        'button': {
                                                                                                            pointerEvents: 'all',
                                                                                                        }},
                                                                                                        mt: '6px',
                                                                                                        width: "100%",
                                                                                                        "& fieldset": {
                                                                                                        border: "1px solid #dedede",
                                                                                                        },
                                                                                                        "& .css-k4qjio-MuiFormHelperText-root": {
                                                                                                        backgroundColor: "#ffffff",
                                                                                                        margin: "0px",
                                                                                                        paddingLeft: "0.5rem",
                                                                                                        },
                                                                                                        "& .MuiOutlinedInput-root": {
                                                                                                            // border: "1px solid #F9F9F9",
                                                                                                            // borderColor: 'rgba(0, 0, 0, 0.23)',
                                                                                                            // borderRadius: '8px',
                                                                                                            'button': {
                                                                                                                pointerEvents: 'all',
                                                                                                            }
                                                                                                        },
                                                                                                        "& fieldset":isRequiredFun(TileElement[1].required)
                                                                                                    }}
        
                                                                                                    {...params}
                                                                                                />
                                                                                                )}
                                                                                            />                     
                                                                                        </LocalizationProvider>
                                                                                    </FormControl>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                        {(TileElement[1].type === "empty") && (
                                                                            <div className="empty-field">
                                                                                    
                                                                                
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                    <div className=" d-flex justify-content-between">
                                                                        <div className="field-error-message">
                                                                            {
                                                                                (!Array.isArray(vertualError[tabIndex][groupContainerI][0].container[tileIndex][1])) && vertualError[tabIndex][groupContainerI][0].container[tileIndex][1].responseError.isError && (
                                                                                    <Typography>{vertualError[tabIndex][groupContainerI][0].container[tileIndex][1].responseError.errorMessage}</Typography>
                                                                                )
                                                                            }
                                                                            
                                                                        </div>
                                                                        {!(pageMoodViewOrEdit === 'view') && isTheirStringLimit(TileElement[1]) && <div className="cha-left-indicator">
                                                                            {showCharLeft(TileElement[1], vertualPayload[tabIndex][groupContainerI][0].container[tileIndex][1].response?.stringResponse?.length)}
                                                                        </div>}
                                                                    </div>
                                                                </>
                                                            )

                                                        } catch(error) {
                                                            console.log(`${errorCodeMessage} 654sdf5468df48 right field TileElement ${tileIndex}` )

                                                            toast.error(
                                                                <div style={{display:"flex", flexDirection:"row"}}>
                                                                    <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                                                                    &nbsp;&nbsp;{error.message}
                                                                </div>
                                                            );
                                                        }
                                                    })()}
                                                    

                                                </div>
                                            </div>


                                        ) : (
                                            

                                            <>
                                                {(() => {
                                                    try{
                                                        return (
                                                            <>
                                                                <div>                                                                
                                                                    {(TileElement[0].type === "text") && (
                                                                        <>
                                                                            <div className="field-row">
                                                                    
                                                                                <div className="text-field">

                                                                                    <div className="field-label" >
                                                                                        {TileElement[0].label}
                                                                                    </div>
                                                                                    <TextField
                                                                                    disabled={!TileElement[0].isEditable}
                                                                                        name={TileElement[0].name}
                                                                                        value={vertualPayload[tabIndex][groupContainerI][0].container[tileIndex][0].response.stringResponse || ''}
                                                                                        onChange={(event) =>
                                                                                            handleChangeVertualPayloadContainer(
                                                                                                deepCloneObj(vertualPayload),
                                                                                                event,
                                                                                                "textField",
                                                                                                tabIndex,
                                                                                                0, //tab row position
                                                                                                TabElement,
                                                                                                tileIndex,
                                                                                                TileElement,
                                                                                                0, //tile row position
                                                                                                groupContainerI,
                                                                                                groupContainerE,
                                                                                                0, //groupContainer row position
                                                                                                deepCloneObj(vertualError)


                                                                                            )
                                                                                        }
                                                                                        multiline={!(pageMoodViewOrEdit === 'view') && (TileElement[0].isMultiTextFieldRow ?? false)}
                                                                                        rows={TileElement[0].textFieldRowSize ?? 1}
                                                                                        id="outlined-multiline-static"
                                                                                        variant="outlined"
                                                                                        size="small"
                                                                                        sx={{
                                                                                            width: '100%',
                                                                                            mt: '6px',
                                                                                            "& fieldset": {
                                                                                                borderRadius: '8px',                              
                                                                                                },
                                                                                            "& fieldset":isRequiredFun(TileElement[0].required)
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                    {(TileElement[0].type === "number") && (
                                                                        <>
                                                                            <div className="field-row">
                                                                    
                                                                                <div className="number-field">

                                                                                    <div className="field-label" >
                                                                                        {TileElement[0].label}
                                                                                    </div>
                                                                                    <TextField
                                                                                    disabled={!TileElement[0].isEditable}
                                                                                        name={TileElement[0].name}
                                                                                        value={vertualPayload[tabIndex][groupContainerI][0].container[tileIndex][0].response.numberResponse || ''}
                                                                                        onChange={(event) =>
                                                                                            handleChangeVertualPayloadContainer(
                                                                                                deepCloneObj(vertualPayload),
                                                                                                event,
                                                                                                "numberField",
                                                                                                tabIndex,
                                                                                                0, //tab row position
                                                                                                TabElement,
                                                                                                tileIndex,
                                                                                                TileElement,
                                                                                                0, //tile row position
                                                                                                groupContainerI,
                                                                                                groupContainerE,
                                                                                                0, //groupContainer row position
                                                                                                deepCloneObj(vertualError)


                                                                                            )
                                                                                        }
                                                                                        multiline={!(pageMoodViewOrEdit === 'view') && (TileElement[0].isMultiTextFieldRow ?? false)}
                                                                                        rows={TileElement[0].textFieldRowSize ?? 1}
                                                                                        id="outlined-multiline-static"
                                                                                        variant="outlined"
                                                                                        size="small"
                                                                                        sx={{
                                                                                            width: '100%',
                                                                                            mt: '6px',
                                                                                            "& fieldset": {
                                                                                                borderRadius: '8px',                              
                                                                                                },
                                                                                            "& fieldset":isRequiredFun(TileElement[0].required)
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                    {(TileElement[0].type === "select") && (
                                                                        <>
                                                                            <div className="field-row">
                                                                                <div className="select-field">
                                                                                    
                                                                                    <div className="field-label" >
                                                                                        {TileElement[0].label}
                                                                                    </div>
                                                                                    {hideIfPageViewMoodAndMultiSelect(pageMoodViewOrEdit, TileElement[0]) && <div>
                                                                                        <FormControl
                                                                                        sx={{width: {md: '100%', sm: '100%'}}}
                                                                                        >
                                                                                            <Select
                                                                                        disabled={!TileElement[0].isEditable}
                                                                                                name={TileElement[0].name}
                                                                                                value={vertualPayload[tabIndex][groupContainerI][0].container[tileIndex][0].response.selectResponse || []}
                                                                                                onChange={(event) =>
                                                                                                    handleChangeVertualPayloadContainer(
                                                                                                        deepCloneObj(vertualPayload),
                                                                                                        event,
                                                                                                        "selectField",
                                                                                                        tabIndex,
                                                                                                        0, //tab row position
                                                                                                        TabElement,
                                                                                                        tileIndex,
                                                                                                        TileElement,
                                                                                                        0, //tile row position
                                                                                                        groupContainerI,
                                                                                                        groupContainerE,
                                                                                                        0, //groupContainer row position
                                                                                                        deepCloneObj(vertualError)


                                                                                                    )
                                                                                                }
                                                                                                renderValue={(selected) =>
                                                                                                    {
                                                                                                        if((TileElement[0].isSelectMenuDynamic === 'true') || (TileElement[0].isSelectMenuDynamic === true)) {
                                                                                                          return getListItemDescription(selected, dropdownData?.[TileElement[0].selectMenuDynamicType], 'name')
                                                                                                        } else {
																																																					return getLabelByValueRenderValue(TileElement[0].options, selected)
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                                sx={{
                                                                                                    width: '100%',
                                                                                                    mt: '6px',
                                                                                                    "& fieldset": {
                                                                                                        borderRadius: '8px',   
                                                                                                        
                                                                                                    },
                                                                                                    "& fieldset":isRequiredFun(TileElement[0].required)
                                                                                                }}
                                                                                                multiple={((TileElement[0].isSingleSelect === 'true') || (TileElement[0].isSingleSelect === true)) ? false : true}
                                                                                                size="small"
                                                                                                labelId='demo-simple-select-label'
                                                                                                id='demo-simple-select'
                                                                                            >
                                                                                                {(() => {
                                                                                                    try{
                                                                                                        return (
                                                                                                            (TileElement[0].isSelectMenuDynamic === 'true') || (TileElement[0].isSelectMenuDynamic === true)) ? (
                                                                                                                dropdownData?.[TileElement[0].selectMenuDynamicType].map((e,i) => {
                                                                                                                    return <MenuItem key={e.id + "-" + i} value={e.id}><ListItemText primary={e.name} /></MenuItem>;
                                                                                                                })
                                                                                                            
                                                                                                            ) : (
                                                                                                                TileElement[0]?.options?.map(
                                                                                                                    (e, i) => {
                                                                                                                    return <MenuItem key={e.value + i} value={e.value}><ListItemText primary={e.label} /></MenuItem>;
                                                                                                                    },
                                                                                                                )
                                                                                                        )
                                                                                                    } catch(error) {
                                                                                                        console.log(`${errorCodeMessage} 51df1b4b8b8f4b left field TileElement ${tileIndex}` )

                                                                                                        toast.error(
                                                                                                            <div style={{display:"flex", flexDirection:"row"}}>
                                                                                                                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                                                                                                                &nbsp;&nbsp;{error.message}
                                                                                                            </div>
                                                                                                        );
                                                                                                    }
                                                                                                })()}
                                                                                            
                                                                                            </Select>
                                                                                        </FormControl>
                                                                                    </div>}
                                                                                    {(() => {
                                                                                        try {
                                                                                            return !((TileElement[0].isSingleSelect === 'true') || (TileElement[0].isSingleSelect === true)) && (vertualPayload[tabIndex][groupContainerI][0].container[tileIndex][0].response.selectResponse) && (vertualPayload[tabIndex][groupContainerI][0].container[tileIndex][0].response.selectResponse.length > 0) && (
                                                                                                <div className="selected-menu">
                                                                                                    {vertualPayload[tabIndex][groupContainerI][0].container[tileIndex][0].response.selectResponse.map((e,i) => 
                                                                                                        <div key={i} className="menu-item-container">
                                                                                                            <div className="title">
                                                                                                                {!((TileElement[0].isSelectMenuDynamic === 'true') || (TileElement[0].isSelectMenuDynamic === true)) ? (
                                                                                                                    getLabelByValue(TileElement[0], e)
                                                                                                                ) : (
                                                                                                                    <>{(getObjectById(dropdownData[TileElement[0].selectMenuDynamicType], e))?.name}</>
                                                                                                                )}
                                                                                                            </div>
                                                                                                            <div className="delete">
                                                                                                                <RxCross1 />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )}
                                                                                                </div>
                                                                                            ) 
                                                                                        } catch(error) {
                                                                                            console.log(`${errorCodeMessage} rdgdg5r48th4d45hd4h left field TileElement ${tileIndex}` )

                                                                                            toast.error(
                                                                                                <div style={{display:"flex", flexDirection:"row"}}>
                                                                                                    <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                                                                                                    &nbsp;&nbsp;{error.message}
                                                                                                </div>
                                                                                            );
                                                                                        }
                                                                                    })()}
                                                                                    
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                    {(TileElement[0].type === "date") && (
                                                                        <>
                                                                            <div className="field-row">
                                                                                <div className="date-field">
                                                                                    
                                                                                    <div className="field-label" >
                                                                                        {TileElement[0].label}
                                                                                    </div>
                                                                                    <div>
                                                                                        <FormControl
                                                                                        sx={{width: {md: '100%', sm: '100%'}}}
                                                                                        >
                                                                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                                                <DesktopDatePicker
                                                                                        disabled={!TileElement[0].isEditable}
                                                                                                    value={vertualPayload[tabIndex][groupContainerI][0].container[tileIndex][0].response.dateResponse}
                                                                                                    onChange={(event) =>
                                                                                                        handleChangeVertualPayloadContainer(
                                                                                                            deepCloneObj(vertualPayload),
                                                                                                            event,
                                                                                                            "dateField",
                                                                                                            tabIndex,
                                                                                                            0, //tab row position
                                                                                                            TabElement,
                                                                                                            tileIndex,
                                                                                                            TileElement,
                                                                                                            0, //tile row position
                                                                                                            groupContainerI,
                                                                                                            groupContainerE,
                                                                                                            0, //groupContainer row position
                                                                                                            deepCloneObj(vertualError)
                                                    
                                                    
                                                                                                        )
                                                                                                    }
                                                                                                    name={TileElement[0].name}
                                                                                                    
                                                                                                    sx={{
                                                                                                        width: '100%',
                                                                                                        borderRadius: '8px',
                                                                                                        "& .MuiOutlinedInput-root": {
                                                                                                            borderRadius: '8px', 
                                                                                                            border: "1px solid #F9F9F9"
                                                                                                        }
                                                                                                    }}
                                                                                                    renderInput={(params) => (
                                                                                                        <TextField
                                                                                                        size={"small"}
                                                                                                        variant="outlined"
                                                                                                        sx={{
                                                                                                            pointerEvents: 'none',
                                                                                                            '& .MuiOutlinedInput-root': {
                                                                                                            'button': {
                                                                                                                pointerEvents: 'all',
                                                                                                            }},
                                                                                                            mt: '6px',
                                                                                                            width: "100%",
                                                                                                            "& fieldset": {
                                                                                                            border: "1px solid #dedede",
                                                                                                            },
                                                                                                            "& .css-k4qjio-MuiFormHelperText-root": {
                                                                                                            backgroundColor: "#ffffff",
                                                                                                            margin: "0px",
                                                                                                            paddingLeft: "0.5rem",
                                                                                                            },
                                                                                                            "& .MuiOutlinedInput-root": {
                                                                                                                // border: "1px solid #F9F9F9",
                                                                                                                // borderColor: 'rgba(0, 0, 0, 0.23)',
                                                                                                                // borderRadius: '8px',
                                                                                                                'button': {
                                                                                                                    pointerEvents: 'all',
                                                                                                                }
                                                                                                            },
                                                                                                            "& fieldset":isRequiredFun(TileElement[0].required)
                                                                                                        }}

                                                                                                        {...params}
                                                                                                    />
                                                                                                    )}
                                                                                                />                     
                                                                                            </LocalizationProvider>
                                                                                        </FormControl>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </div>
                                                                <div className=" d-flex justify-content-between">
                                                                    <div className="field-error-message">
                                                                        {
                                                                            (!Array.isArray(vertualError[tabIndex][groupContainerI][0].container[tileIndex][0])) && vertualError[tabIndex][groupContainerI][0].container[tileIndex][0].responseError.isError && (
                                                                                <Typography>{vertualError[tabIndex][groupContainerI][0].container[tileIndex][0].responseError.errorMessage}</Typography>
                                                                            )
                                                                        }
                                                                        
                                                                    </div>
                                                                    {!(pageMoodViewOrEdit === 'view') && isTheirStringLimit(TileElement[0]) && <div className="cha-left-indicator">
                                                                        {showCharLeft(TileElement[0], vertualPayload[tabIndex][groupContainerI][0].container[tileIndex][0].response?.stringResponse?.length)}
                                                                    </div>}
                                                                </div>
                                                            </>
                                                        )
                                                    } catch(error) {
                                                        console.log(`${errorCodeMessage} 1sd3v5165b1f left field TileElement ${tileIndex}` )

                                                        toast.error(
                                                            <div style={{display:"flex", flexDirection:"row"}}>
                                                                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                                                                &nbsp;&nbsp;{error.message}
                                                            </div>
                                                        );
                                                    }
                                                })()}
                                                

                                            </>


                                        )}


                    </div>
                    
        

        }
      }

      const createDynamicFormContainer = (TabElement, tabIndex, tempFormDataForContainer) => {
        
        if (tempFormDataForContainer[tabIndex] && (tempFormDataForContainer[tabIndex].length > 0)) {
            return (
                <div className="">
                    
                    <div className="">

                        {
                            tempFormDataForContainer[tabIndex].map((groupContainerE, groupContainerI) => {
                                return (
                                    <div className="form-container-group">
                                        <div className="container-group-header">
                                            <div className="d-flex">
                                                <div className="group-header-title">
                                                    {`${groupContainerE[0].label} ${groupContainerI + 1}`}
                                                </div>
                                                {groupContainerE[0]?.progressStatus?.label && (pageMoodViewOrEdit === 'view') && 
                                                    <div className="group-header-status" style={{color: groupContainerE[0]?.progressStatus?.color ?? '#00AA5A'}}>
                                                        {`${groupContainerE[0]?.progressStatus?.label}`}
                                                    </div>
                                                }

                                            </div>
                                            <div className="group-header-divider-line">
                                                
                                            </div>
                                            <div className="group-header-delete">

                                            {!(pageMoodViewOrEdit === 'view') && <div className="head-delete">
                                                <div className="delete-icon">
                                                    <MdDelete />
                                                </div>
                                                <Button onClick={() => setTimeout(() => {
                                                        handleDeleteContainer(
                                                            TabElement, tabIndex, groupContainerE, groupContainerI, JSON.parse(JSON.stringify(formDataForContainer)), JSON.parse(JSON.stringify(vertualPayload)),JSON.parse(JSON.stringify(vertualError)), JSON.parse(JSON.stringify(formData)))
                                                    }, [50])}>
                                                    <div className="title">
                                                        {/* Delete this goal */}
                                                        {`Delete ${groupContainerE[0].label} ${groupContainerI + 1}`}
                                                    </div>
                                                </Button>
                                            </div>}
                                                
                                            </div>
                                        </div>

                                        <div className="container-group-field">
                                            <div className="group-field-line-left" />
                                        
                                                <div className="group-field-margin" >
                                                    <div className="form-group-contant">
                                                        <div className="group-contant">

                                                            {
                                                                groupContainerE[0].container.map((TileElement, tileIndex) => {

                                                                    if(isEmptyNullUndefined(TileElement)) {
                                                                        return
                                                                    } else {

                                                                        return randerDynamicTileElement(TileElement, tileIndex, TabElement, tabIndex, groupContainerE,groupContainerI)
                                                                    }


                                                                            
                                                                })
                                                            }
                                                            
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                        

                                        
                                        
                                        
                                    </div>
                                )
                            })

                        }
                    </div>
                                    
                </div>
            )
        } else {
            return;
        }


      }

      const createDynamicForm = (data) => {

        if (!data || !checkLengthOfArrayEqualOrNot({vertualPayload, vertualError, formDataForContainer})) {
          return
        } 
        
    
        const tempData = structuredClone(data);
        let dom = [];
    
        if (tempData && tempData?.length > 0) {
            return tempData?.map((TabElement, tabIndex) => (
              <div key={tabIndex}>
                {((TabElement[0].isMultiField === true) || (TabElement[0].isMultiField === 'true')) ? (
                        <div className="field-row">


                            <div className="row-half">
                                <div>
                                    {(() => {
                                        try{
                                            return (<>

                                                {(TabElement[0].type === "text") && (
                                                    <div className="text-field">
                
                                                        <div className="field-label" >
                                                            {TabElement[0].label}
                                                        </div>
                                                        <TextField
                                                            disabled={!TabElement[0].isEditable}
                                                            value={vertualPayload[tabIndex][0].response.stringResponse || ''}
                                                            name={TabElement[0].name}
                                                            onChange={(event) =>
                                                                handleChangeVertualPayload(
                                                                    deepCloneObj(vertualPayload),
                                                                    event,
                                                                    "textField",
                                                                    tabIndex,
                                                                    0, //row position
                                                                    TabElement, //TabElement
                                                                    deepCloneObj(vertualError)
                                                                )
                                                            }
                                                            multiline={!(pageMoodViewOrEdit === 'view') && (TabElement[0].isMultiTextFieldRow ?? false)}
                                                            rows={TabElement[0].textFieldRowSize ?? 1}
                                                            id="outlined-multiline-static"
                                                            variant="outlined"
                                                            size="small"
                                                            sx={{
                                                                width: '100%',
                                                                mt: '6px',
                                                                "& fieldset": {
                                                                borderRadius: '8px',                              
                                                                },
                                                                "& fieldset":isRequiredFun(TabElement[0].required)
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                                {(TabElement[0].type === "number") && (
                                                    <div className="number-field">
                
                                                        <div className="field-label" >
                                                            {TabElement[0].label}
                                                        </div>
                                                        <TextField
                                                            disabled={!TabElement[0].isEditable}
                                                            value={vertualPayload[tabIndex][0].response.numberResponse || ''}
                                                            name={TabElement[0].name}
                                                            onChange={(event) =>
                                                                handleChangeVertualPayload(
                                                                    deepCloneObj(vertualPayload),
                                                                    event,
                                                                    "numberField",
                                                                    tabIndex,
                                                                    0, //row position
                                                                    null, //TabElement
                                                                    deepCloneObj(vertualError)
                                                                )
                                                            }
                                                            multiline={!(pageMoodViewOrEdit === 'view') && (TabElement[0].isMultiTextFieldRow ?? false)}
                                                            rows={TabElement[0].textFieldRowSize ?? 1}
                                                            id="outlined-multiline-static"
                                                            variant="outlined"
                                                            size="small"
                                                            sx={{
                                                                width: '100%',
                                                                mt: '6px',
                                                                "& fieldset": {
                                                                    borderRadius: '8px',                              
                                                                },
                                                                "& fieldset":isRequiredFun(TabElement[0].required)
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                                {(TabElement[0].type === "select") && (
                                                    <div className="select-field">
                                                    
                                                    <div className="field-label" >
                                                        {TabElement[0].label}
                                                    </div>
                                                    {hideIfPageViewMoodAndMultiSelect(pageMoodViewOrEdit, TabElement[0]) && <div>
                                                        <FormControl
                                                        sx={{width: {md: '100%', sm: '100%'}}}
                                                        >
                                                        <Select
                                                        disabled={!TabElement[0].isEditable}
                                                            value={vertualPayload[tabIndex][0].response.selectResponse || []}
                                                            renderValue={(selected) =>
                                                                {
																																	if (TabElement[0].isSelectMenuDynamic === 'true' || TabElement[0].isSelectMenuDynamic === true) {
																																		return getListItemDescription(selected, dropdownData?.[TabElement[0].selectMenuDynamicType], 'name');
																																	} else {
																																		return getLabelByValueRenderValue(TabElement[0].options, selected)
																																	}
                                                                }
                                                            }
                                                            name={TabElement[0].name}
                                                            onChange={(event) =>
                                                                handleChangeVertualPayload(
                                                                    deepCloneObj(vertualPayload),
                                                                    event,
                                                                    "selectField",
                                                                    tabIndex,
                                                                    0, //row position
                                                                    TabElement,
                                                                    deepCloneObj(vertualError)
                                                                )
                                                            }
                                                            sx={{
                                                                width: '100%',
                                                                mt: '6px',
                                                                "& fieldset": { 
                                                                    borderRadius: '8px',   
                                                                    
                                                                },
                                                                "& fieldset":isRequiredFun(TabElement[0].required)
                                                            }}
                                                            size="small"
                                                            labelId='demo-simple-select-label'
                                                            id='demo-simple-select'
                                                            multiple={((TabElement[0].isSingleSelect === 'true') || (TabElement[0].isSingleSelect === true)) ? false : true}
                                                        >
                                                            {(() => {
                                                                try{
                                                                    return (
                                                                            (TabElement[0].isSelectMenuDynamic === 'true') || (TabElement[0].isSelectMenuDynamic === true)) ? (
                                                                                dropdownData?.[TabElement[0].selectMenuDynamicType].map((e,i) => {
                                                                                    return <MenuItem key={e.id + "-" + i} value={e.id}><ListItemText primary={e.name} /></MenuItem>;
                                                                                })
                                                                            
                                                                            ) : (
                                                                                TabElement[0]?.options?.map(
                                                                                    (e, i) => {
                                                                                    return <MenuItem key={e.value + i} value={e.value}><ListItemText primary={e.label} /></MenuItem>;
                                                                                    },
                                                                                )
                                                                            )
                                                                } catch(error) {
                                                                    console.log(`${errorCodeMessage} 454745sd45745457487, right field tabIndex ${tabIndex}` )
                                                                    toast.error(
                                                                        <div style={{display:"flex", flexDirection:"row"}}>
                                                                            <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                                                                            &nbsp;&nbsp;{error.message}
                                                                        </div>
                                                                    );
                                                                }
                                                            })()}
                                                        
                                                        </Select>
                                                        </FormControl>
                                                    </div>}
                                                    {(() => {
                                                        try {
                                                            return !((TabElement[0].isSingleSelect === 'true') || (TabElement[0].isSingleSelect === true)) && (vertualPayload[tabIndex][0].response?.selectResponse) && (vertualPayload[tabIndex][0].response.selectResponse.length > 0) && (
                                                                <div className="selected-menu">
                                                                    {vertualPayload[tabIndex][0].response.selectResponse.map((e,i) => 
                                                                        <div key={i} className="menu-item-container">
                                                                            <div className="title">
                                                                                {!((TabElement[0].isSelectMenuDynamic === 'true') || (TabElement[0].isSelectMenuDynamic === true)) ? (
																																									getLabelByValue(TabElement[0], e)
                                                                                ) : (
                                                                                    <>{(getObjectById(dropdownData[TabElement[0].selectMenuDynamicType], e))?.name}</>
                                                                                )}
                                                                            </div>
                                                                            <div className="delete">
                                                                                <RxCross1 />
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            ) 
                                                        } catch(error) {
                                                            console.log(`${errorCodeMessage} g1gtrtgrge51r51fhtrhtrh, left field tabIndex ${tabIndex}` )
                                                            toast.error(
                                                                <div style={{display:"flex", flexDirection:"row"}}>
                                                                    <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                                                                    &nbsp;&nbsp;{error.message}
                                                                </div>
                                                            );
                                                        }
                                                    })()}
                                                    
                                                </div>
                                                )}
                                                {(TabElement[0].type === "date") && (
                                                    <div className="date-field">
                                                            
                                                        <div className="field-label" >
                                                            {TabElement[0].label}
                                                        </div>
                                                        <div>
                                                            <FormControl
                                                            sx={{width: {md: '100%', sm: '100%'}}}
                                                            >
                                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                    <DesktopDatePicker
                                                            disabled={!TabElement[0].isEditable}
                                                                        name={TabElement[0].name}
                                                                        value={vertualPayload[tabIndex][0].response.dateResponse}
                                                                        onChange={(event) =>
                                                                            handleChangeVertualPayload(
                                                                                deepCloneObj(vertualPayload),
                                                                                event,
                                                                                "dateField",
                                                                                tabIndex,
                                                                                0, //row position
                                                                                null, //TabElement
                                                                                deepCloneObj(vertualError)
                                                                            )
                                                                        }
                                                                        
                                                                        sx={{
                                                                            width: '100%',
                                                                            borderRadius: '8px',
                                                                            "& .MuiOutlinedInput-root": {
                                                                                borderRadius: '8px', 
                                                                                border: "1px solid #F9F9F9"
                                                                            }
                                                                        }}
                                                                        renderInput={(params) => (
                                                                            <TextField
                                                                            size={"small"}
                                                                            variant="outlined"
                                                                            sx={{
                                                                                pointerEvents: 'none',
                                                                                '& .MuiOutlinedInput-root': {
                                                                                'button': {
                                                                                    pointerEvents: 'all',
                                                                                }},
                                                                                mt: '6px',
                                                                                width: "100%",
                                                                                "& fieldset": {
                                                                                border: "1px solid #dedede",
                                                                                },
                                                                                "& .css-k4qjio-MuiFormHelperText-root": {
                                                                                backgroundColor: "#ffffff",
                                                                                margin: "0px",
                                                                                paddingLeft: "0.5rem",
                                                                                },
                                                                                "& .MuiOutlinedInput-root": {
                                                                                    // border: "1px solid #F9F9F9",
                                                                                    // borderColor: 'rgba(0, 0, 0, 0.23)',
                                                                                    // borderRadius: '8px',
                                                                                    'button': {
                                                                                        pointerEvents: 'all',
                                                                                    }
                                                                                },
                                                                                "& fieldset":isRequiredFun(TabElement[0].required)
                                                                            }}
                
                                                                            {...params}
                                                                        />
                                                                        )}
                                                                    />                     
                                                                </LocalizationProvider>
                                                            </FormControl>
                                                        </div>
                                                    </div>
                                                )}
                                                {(TabElement[0].type === "empty") && (
                                                    <div className="empty-field">
                                                            
                                                        
                                                    </div>
                                                )}
                                                {(TabElement[0].type === "file") && (
                                                    <div 
                                                        style={{
                                                            pointerEvents: isUploadingFile
                                                                ? "none"
                                                                : "all",
                                                            opacity: isUploadingFile ? "60%" : "100%",
                                                            }}
                                                        className="upload-document"
                                                    >
                                                        <div className="field-label" >
                                                            {TabElement[0].label}
                                                        </div>
                                                        <div class="doc-distibute">
                                                            {console.log('files', files)}
                                                            {files && files.map((e, index) => (
                                                                <div className="doc-row">
                                                                    <div className="">
                                                                        <>
                                                                            <div className="d-flex justify-content-between">
                                                                                <div className="d-flex left-position">
                                                                                    
                                                                                    <div>
                                                                                        <div className="d-flex align-items-center">
                                                                                            <div className="round-box">
                                                                                                <div className=" align-items-center icon-container"><IoDocumentTextOutline /></div>
                                                                                            </div>
                                                                                            <div onClick={() => window.open(e.path)} className="title"> {e.orignalFileName}</div>
                                                                                            
                                                                                        </div>
                                                                                        <div className="delete d-flex">
                                                                                            <div className="left-gap">
                    
                                                                                            </div>
                                                                                            {!(pageMoodViewOrEdit === 'view') && <div 
                                                                                                onClick={() =>
                                                                                                    // handleDeleteDoc(index)
                                                                                                    handleDeleteDocAPI(e.id,index)
                                                                                                } 
                                                                                                className="title"
                                                                                                style={{
                                                                                                    opacity: loaderDeleteDoc ? '40%' : '100%',
                                                                                                    pointerEvents: loaderDeleteDoc ? 'none' : 'all',
                                                                                                }}
                                                                                            >
                                                                                                Remove
                    
                                                                                            </div>}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div></div>
                                                                            </div>
                                                                            </>
                                                                    </div>
                                                                </div>
                                                            ))}

                                                            {filesLocal && filesLocal.map((e, index) => (
                                                                <div className="doc-row">
                                                                    <div className="">
                                                                        <>
                                                                            <div className="d-flex justify-content-between">
                                                                                <div className="d-flex left-position justify-content-between">
                                                                                    
                                                                                    <div>
                                                                                        <div className="d-flex align-items-center">
                                                                                            <div className="round-box">
                                                                                                <div className=" align-items-center icon-container"><IoDocumentTextOutline /></div>
                                                                                            </div>
                                                                                            <div 
                                                                                                onClick={() => {
                                                                                                    const file = e; // Assuming 'e' is the file object passed in the map function
                                                                                                    const reader = new FileReader();
                                                                                            
                                                                                                    // Read the file as a data URL
                                                                                                    reader.onload = function(event) {
                                                                                                        const newTab = window.open();
                                                                                                        newTab.document.body.innerHTML = `<img src="${event.target.result}" style="width:100%;height:auto;">`;
                                                                                                    };
                                                                                            
                                                                                                    // Trigger the reading of the file (for image files, etc.)
                                                                                                    reader.readAsDataURL(file);
                                                                                                }} 
                                                                                                className="title"
                                                                                            > {e.name}</div>
                                                                                            
                                                                                        </div>
                                                                                        <div className="delete d-flex">
                                                                                            <div className="left-gap">
                    
                                                                                            </div>
                                                                                            {!(pageMoodViewOrEdit === 'view') && <div 
                                                                                                onClick={() =>
                                                                                                    handleDeleteDocLocal(index)
                                                                                                } 
                                                                                                className="title"
                                                                                                style={{
                                                                                                    opacity: loaderDeleteDoc ? '40%' : '100%',
                                                                                                    // opacity: '40%',
                                                                                                    pointerEvents: loaderDeleteDoc ? 'none' : 'all',
                                                                                                }}
                                                                                                
                                                                                            >
                                                                                                Remove
                    
                                                                                            </div>}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className=" d-flex align-items-center">
                                                                                        <Button style={{
                                                                                            borderColor: '#004259',
                                                                                            color: '#004259',
                                                                                            borderRadius: '8px',

                                                                                            // fontFamily: 'Inter',
                                                                                            fontSize: '14px',
                                                                                            fontWeight: '500',
                                                                                            opacity: isUploadingFile ? '40%' : '100%',
                                                                                            pointerEvents: isUploadingFile ? 'none' : 'all',

                                                                                            }} 

                                                                                            variant="outlined"
                                                                                            onClick={() => processToUpload(e, index, structuredClone(filesLocal))} 
                                                                                        >
                                                                                            Upload

                                                                                        </Button>
                                                                                    </div>
                                                                                </div>
                                                                                <div>

                                                                                </div>
                                                                            </div>
                                                                            </>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                            
                                                        </div>
                                                        {!(pageMoodViewOrEdit === 'view') && <div>
                                                            <input
                                                                type="file"
                                                                // accept="image/*"
                                                                onChange={handleImageChange}
                                                                style={{ 
                                                                    display: "none",
                                                                    
                                                                }}
                                                                id="imageInput"
                                                            />
                                                            <label
                                                                htmlFor="imageInput"
                                                                className="btn btn-dark edit-button"
                                                                style={{
                                                                    opacity: (!isEmptyNullUndefined(TabElement[0].maxFileLimits) && TabElement[0].maxFileLimits <= (+filesLocal?.length + +files?.length)) ? '40%' : '100%',
                                                                    pointerEvents: (!isEmptyNullUndefined(TabElement[0].maxFileLimits) && TabElement[0].maxFileLimits <= (+filesLocal?.length + +files?.length)) ? 'none' : 'all'  
                                                                }}
                                                            >
                                                                {/* <FaCamera /> */}
                                                                {(() => {
                                                                    if((files && files.length > 0) || filesLocal && filesLocal.length > 0) {
                                                                        return '+ Add Another Document'

                                                                    } else {
                                                                        return '+ Add Document'
                                                                    }
                                                                })()}
                                                            </label>
                                                            <div className=" w-50">
                                                                {isUploadingFile && (
                                                                    <LinearProgress />
                                                                )}
                                                            </div>
                                                        
                                                        </div>}
                                                    </div>
                                                )}
                                            
                                            </>)
                                        } catch(error) {
                                            console.log(`${errorCodeMessage} 6845s4df64df45, left field tabIndex ${tabIndex}` )

                                            toast.error(
                                                <div style={{display:"flex", flexDirection:"row"}}>
                                                    <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                                                    &nbsp;&nbsp;{error.message}
                                                </div>
                                            );
                                        }
                                    })()}
                                </div>
                                <div className=" d-flex justify-content-between">
                                    <div className="field-error-message">
                                        {
                                            (!Array.isArray(vertualError[tabIndex][0])) && vertualError[tabIndex][0].responseError.isError && (
                                                <Typography>{vertualError[tabIndex][0].responseError.errorMessage}</Typography>
                                            )
                                        }
                                        
                                    </div>
                                    {!(pageMoodViewOrEdit === 'view') && isTheirStringLimit(TabElement[0]) && <div className="cha-left-indicator">
                                        {showCharLeft(TabElement[0], vertualPayload[tabIndex][0].response?.stringResponse?.length)}
                                    </div>}
                                </div>
                            </div>


                            <div className="row-half">
                                <div>
                                    {(() => {
                                        try{
                                            return (
                                                <>
                                                    {(TabElement[1].type === "text") && (
                                                        <div className="text-field">

                                                            <div className="field-label" >
                                                                {TabElement[1].label}
                                                            </div>
                                                            <TextField
                                                                disabled={!TabElement[1].isEditable}
                                                                name={TabElement[1].name}
                                                                value={vertualPayload[tabIndex][1].response.stringResponse || ''}
                                                                onChange={(event) =>
                                                                    handleChangeVertualPayload(
                                                                        deepCloneObj(vertualPayload),
                                                                        event,
                                                                        "textField",
                                                                        tabIndex,
                                                                        1, //row position
                                                                        TabElement, //TabElement
                                                                        deepCloneObj(vertualError)
                                                                    )
                                                                }
                                                                multiline={!(pageMoodViewOrEdit === 'view') && (TabElement[1].isMultiTextFieldRow ?? false)}
                                                                rows={TabElement[1].textFieldRowSize ?? 1}
                                                                id="outlined-multiline-static"
                                                                variant="outlined"
                                                                size="small"
                                                                sx={{
                                                                    width: '100%',
                                                                    mt: '6px',
                                                                    "& fieldset": {
                                                                        borderRadius: '8px',                              
                                                                    },
                                                                    "& fieldset":isRequiredFun(TabElement[1].required)
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                    {(TabElement[1].type === "number") && (
                                                        <div className="number-field">

                                                            <div className="field-label" >
                                                                {TabElement[1].label}
                                                            </div>
                                                            <TextField
                                                                disabled={!TabElement[1].isEditable}
                                                                name={TabElement[1].name}
                                                                value={vertualPayload[tabIndex][1].response.numberResponse || ''}
                                                                onChange={(event) =>
                                                                    handleChangeVertualPayload(
                                                                        deepCloneObj(vertualPayload),
                                                                        event,
                                                                        "numberField",
                                                                        tabIndex,
                                                                        1, //row position
                                                                        null, //TabElement
                                                                        deepCloneObj(vertualError)
                                                                    )
                                                                }
                                                                multiline={!(pageMoodViewOrEdit === 'view') && (TabElement[1].isMultiTextFieldRow ?? false)}
                                                                rows={TabElement[1].textFieldRowSize ?? 1}
                                                                id="outlined-multiline-static"
                                                                variant="outlined"
                                                                size="small"
                                                                sx={{
                                                                    width: '100%',
                                                                    mt: '6px',
                                                                    "& fieldset": {
                                                                        borderRadius: '8px',                              
                                                                    },
                                                                    "& fieldset":isRequiredFun(TabElement[1].required)
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                    {(TabElement[1].type === "select") && (
                                                        <div className="select-field">
                                                        
                                                            <div className="field-label" >
                                                                {TabElement[1].label}
                                                            </div>
                                                            {hideIfPageViewMoodAndMultiSelect(pageMoodViewOrEdit, TabElement[1]) && <div>
                                                                <FormControl
                                                                sx={{width: {md: '100%', sm: '100%'}}}
                                                                >
                                                                <Select
                                                                disabled={!TabElement[1].isEditable}
                                                                    name={TabElement[1].name}
                                                                    value={vertualPayload[tabIndex][1].response.selectResponse || []}
                                                                    onChange={(event) =>
                                                                        handleChangeVertualPayload(
                                                                            deepCloneObj(vertualPayload),
                                                                            event,
                                                                            "selectField",
                                                                            tabIndex,
                                                                            1, //row position
                                                                            TabElement,
                                                                            deepCloneObj(vertualError)
                                                                        )
                                                                    }
                                                                    renderValue={(selected) =>
                                                                        {
                                                                            if((TabElement[1].isSelectMenuDynamic === 'true') || (TabElement[1].isSelectMenuDynamic === true)) {
                                                                              return getListItemDescription(selected, dropdownData?.[TabElement[1].selectMenuDynamicType], 'name')
                                                                            } else {
																																							return getLabelByValueRenderValue(TabElement[1].options, selected)
                                                                            }
                                                                        }
                                                                    }

                                                                    sx={{
                                                                        width: '100%',
                                                                        mt: '6px',
                                                                        "& fieldset": { 
                                                                            borderRadius: '8px',   
                                                                        },
                                                                        "& fieldset":isRequiredFun(TabElement[1].required)
                                                                    }}
                                                                    size="small"
                                                                    labelId='demo-simple-select-label'
                                                                    id='demo-simple-select'
                                                                    multiple={((TabElement[1].isSingleSelect === 'true') || (TabElement[1].isSingleSelect === true)) ? false : true}
                                                                >
                                                                    {(() => {
                                                                        try{
                                                                            return (
                                                                                (TabElement[1].isSelectMenuDynamic === 'true') || (TabElement[1].isSelectMenuDynamic === true)) ? (
                                                                                    dropdownData?.[TabElement[1].selectMenuDynamicType].map((e,i) => {
                                                                                        return <MenuItem key={e.id + "-" + i} value={e.id}><ListItemText primary={e.name} /></MenuItem>;
                                                                                    })
                                                                                
                                                                                ) : (
                                                                                    TabElement[1]?.options?.map(
                                                                                        (e, i) => {
                                                                                        return <MenuItem key={e.value + i} value={e.value}><ListItemText primary={e.label} /></MenuItem>;
                                                                                        },
                                                                                    )
                                                                                )
                                                                        } catch(error) {
                                                                            console.log(`${errorCodeMessage} 54796sdfg52145, right field tabIndex ${tabIndex}` )
                                                                            toast.error(
                                                                                <div style={{display:"flex", flexDirection:"row"}}>
                                                                                    <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                                                                                    &nbsp;&nbsp;{error.message}
                                                                                </div>
                                                                            );
                                                                        }
                                                                    })()}
                                                                </Select>
                                                                </FormControl>
                                                            </div>}
                                                            {(() => {
                                                                try {
                                                                    return !((TabElement[1].isSingleSelect === 'true') || (TabElement[1].isSingleSelect === true)) && (vertualPayload[tabIndex][1].response?.selectResponse) && (vertualPayload[tabIndex][1].response.selectResponse.length > 0) && (
                                                                        <div className="selected-menu">
                                                                            {vertualPayload[tabIndex][1].response.selectResponse.map((e,i) => 
                                                                                <div className="menu-item-container">
                                                                                    <div className="title">
                                                                                        {!((TabElement[1].isSelectMenuDynamic === 'true') || (TabElement[1].isSelectMenuDynamic === true)) ? (
                                                                                            getLabelByValue(TabElement[1], e)
                                                                                        ) : (
                                                                                            <>{(getObjectById(dropdownData[TabElement[1].selectMenuDynamicType], e))?.name}</>
                                                                                        )}
                                                                                    </div>
                                                                                    <div className="delete">
                                                                                        <RxCross1 />
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    ) 
                                                                } catch (error) {
                                                                    console.log(`${errorCodeMessage} 5d51h5t4h8g5r451b5bg, right field tabIndex ${tabIndex}` )

                                                                    toast.error(
                                                                        <div style={{display:"flex", flexDirection:"row"}}>
                                                                            <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                                                                            &nbsp;&nbsp;{error.message}
                                                                        </div>
                                                                    );
                                                                }
                                                            })()}
                                                           
                                                        </div>
                                                    )}
                                                    {(TabElement[1].type === "date") && (
                                                        <div className="date-field">
                                                                
                                                            <div className="field-label" >
                                                                {TabElement[1].label}
                                                            </div>
                                                            <div>
                                                                <FormControl
                                                                sx={{width: {md: '100%', sm: '100%'}}}
                                                                >
                                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                        <DesktopDatePicker
                                                                disabled={!TabElement[1].isEditable}
                                                                            name={TabElement[1].name}
                                                                            value={vertualPayload[tabIndex][1].response.dateResponse || ''}
                                                                            onChange={(event) =>
                                                                                handleChangeVertualPayload(
                                                                                    deepCloneObj(vertualPayload),
                                                                                    event,
                                                                                    "dateField",
                                                                                    tabIndex,
                                                                                    1, //row position
                                                                                    null, //TabElement
                                                                                    deepCloneObj(vertualError)
                                                                                )
                                                                            }
                                                                            
                                                                            sx={{
                                                                                width: '100%',
                                                                                borderRadius: '8px',
                                                                                "& .MuiOutlinedInput-root": {
                                                                                    borderRadius: '8px', 
                                                                                    border: "1px solid #F9F9F9"
                                                                                }
                                                                            }}
                                                                            renderInput={(params) => (
                                                                                <TextField
                                                                                disabled={!TabElement[1].isEditable}
                                                                                size={"small"}
                                                                                variant="outlined"
                                                                                sx={{
                                                                                    pointerEvents: 'none',
                                                                                    '& .MuiOutlinedInput-root': {
                                                                                    'button': {
                                                                                        pointerEvents: 'all',
                                                                                    }},
                                                                                    mt: '6px',
                                                                                    width: "100%",
                                                                                    "& fieldset": {
                                                                                    border: "1px solid #dedede",
                                                                                    },
                                                                                    "& .css-k4qjio-MuiFormHelperText-root": {
                                                                                    backgroundColor: "#ffffff",
                                                                                    margin: "0px",
                                                                                    paddingLeft: "0.5rem",
                                                                                    },
                                                                                    "& .MuiOutlinedInput-root": {
                                                                                        // border: "1px solid #F9F9F9",
                                                                                        // borderColor: 'rgba(0, 0, 0, 0.23)',
                                                                                        // borderRadius: '8px',
                                                                                        'button': {
                                                                                            pointerEvents: 'all',
                                                                                        }
                                                                                    },
                                                                                    "& fieldset":isRequiredFun(TabElement[0].required)
                                                                                }}

                                                                                {...params}
                                                                            />
                                                                            )}
                                                                        />                     
                                                                    </LocalizationProvider>
                                                                </FormControl>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {(TabElement[1].type === "empty") && (
                                                        <div className="empty-field">
                                                                
                                                            
                                                        </div>
                                                    )}
                                                </>
                                            )
                                        } catch(error) {
                                            console.log(`${errorCodeMessage} 546846hcgfcf151654, left field tabIndex ${tabIndex}` )

                                            toast.error(
                                                <div style={{display:"flex", flexDirection:"row"}}>
                                                    <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                                                    &nbsp;&nbsp;{error.message}
                                                </div>
                                            );
                                        }
                                    })()}
                                </div>
                                <div className=" d-flex justify-content-between">
                                    <div className="field-error-message">
                                        {
                                            (!Array.isArray(vertualError[tabIndex][1])) && vertualError[tabIndex][1].responseError.isError && (
                                                <Typography>{vertualError[tabIndex][1].responseError.errorMessage}</Typography>
                                            )
                                        }
                                        
                                    </div>
                                    {!(pageMoodViewOrEdit === 'view') && isTheirStringLimit(TabElement[1]) && <div className="cha-left-indicator">
                                        {showCharLeft(TabElement[1], vertualPayload[tabIndex][1].response?.stringResponse?.length)}
                                    </div>}
                                </div>
                                

                            </div>

                        </div>
                        // <></>
                ) : (
                    (TabElement[0].container.length > 0) ? (
                        <>
                        {createDynamicFormContainer(TabElement, tabIndex, structuredClone(formDataForContainer))}

                        {!(pageMoodViewOrEdit === 'view') && <div className="container-group-add-more-container">
                            <Button 
                                onClick={() => setTimeout(() => {
                                    handleAddMoreContainer(
                                        TabElement, tabIndex, JSON.parse(JSON.stringify(formDataForContainer)), JSON.parse(JSON.stringify(vertualPayload)),JSON.parse(JSON.stringify(vertualError)) , JSON.parse(JSON.stringify(formData)))
                                }, [50])} 
                                style={{
                                    opacity: (!isEmptyNullUndefined(TabElement[0].maxContainerLimits) && TabElement[0].maxContainerLimits <= (+vertualPayload[tabIndex].length)) ? '40%' : '100%',
                                    pointerEvents: (!isEmptyNullUndefined(TabElement[0].maxContainerLimits) && TabElement[0].maxContainerLimits <= (+vertualPayload[tabIndex].length)) ? 'none' : 'all'  
                                }}
                            >
                                <div 
                                    className="title"
                                    
                                    >
                                    {vertualPayload[tabIndex].length ? `+ Add More ${TabElement[0].label}` : `+ Add A ${TabElement[0].label}`}
                                </div>

                            </Button>
                        </div>}
                        
                        </>
                    ) : (
                        <>
                            <div>
                                {(() => {
                                    try{
                                        return (
                                            <>
                                                {(TabElement[0].type === "text") && (
                                                    <>
                                                        <div className="field-row">
                                                
                                                            <div className="text-field">

                                                                <div className="field-label" >
                                                                    {TabElement[0].label}
                                                                </div>
                                                                <TextField
                                                                    disabled={!TabElement[0].isEditable}
                                                                    name={TabElement[0].name}
                                                                    value={vertualPayload[tabIndex][0].response.stringResponse || ''}
                                                                    onChange={(event) =>
                                                                        handleChangeVertualPayload(
                                                                            deepCloneObj(vertualPayload),
                                                                            event,
                                                                            "textField",
                                                                            tabIndex,
                                                                            0, //row position
                                                                            TabElement, //TabElement
                                                                            deepCloneObj(vertualError)
                                                                        )
                                                                    }
                                                                    multiline={!(pageMoodViewOrEdit === 'view') && (TabElement[0].isMultiTextFieldRow ?? false)}
                                                                    rows={TabElement[0].textFieldRowSize ?? 1}
                                                                    id="outlined-multiline-static"
                                                                    variant="outlined"
                                                                    size="small"
                                                                    sx={{
                                                                        width: '100%',
                                                                        mt: '6px',
                                                                        "& fieldset": {
                                                                            borderRadius: '8px',                              
                                                                        },
                                                                        "& fieldset":isRequiredFun(TabElement[0].required)
                                                                        }
                                                                
                                                                }
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                                {(TabElement[0].type === "number") && (
                                                    <>
                                                        <div className="field-row">
                                                
                                                            <div className="number-field">

                                                                <div className="field-label" >
                                                                    {TabElement[0].label}
                                                                </div>
                                                                <TextField
                                                                    disabled={!TabElement[0].isEditable}
                                                                    name={TabElement[0].name}
                                                                    value={vertualPayload[tabIndex][0].response.numberResponse || ''}
                                                                    onChange={(event) =>
                                                                        handleChangeVertualPayload(
                                                                            deepCloneObj(vertualPayload),
                                                                            event,
                                                                            "numberField",
                                                                            tabIndex,
                                                                            0, //row position
                                                                            null,  //TabElement
                                                                            deepCloneObj(vertualError)
                                                                        )
                                                                    }
                                                                    multiline={!(pageMoodViewOrEdit === 'view') && (TabElement[0].isMultiTextFieldRow ?? false)}
                                                                    rows={TabElement[0].textFieldRowSize ?? 1}
                                                                    id="outlined-multiline-static"
                                                                    variant="outlined"
                                                                    size="small"
                                                                    sx={{
                                                                        width: '100%',
                                                                        mt: '6px',
                                                                        "& fieldset": {
                                                                            borderRadius: '8px',                              
                                                                            },
                                                                        "& fieldset":isRequiredFun(TabElement[0].required)
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                                {(TabElement[0].type === "select") && (
                                                    <>
                                                        <div className="field-row">
                                                            <div className="select-field">
                                                                
                                                                <div className="field-label" >
                                                                    {TabElement[0].label}
                                                                </div>
                                                                {hideIfPageViewMoodAndMultiSelect(pageMoodViewOrEdit, TabElement[0]) && <div>
                                                                    <FormControl
                                                                    sx={{width: {md: '100%', sm: '100%'}}}
                                                                    >
                                                                        <Select
                                                                            disabled={!TabElement[0].isEditable}
                                                                            name={TabElement[0].name}
                                                                            value={vertualPayload[tabIndex][0].response.selectResponse || []}
                                                                            renderValue={(selected) =>
                                                                                {
                                                                                    if((TabElement[0].isSelectMenuDynamic === 'true') || (TabElement[0].isSelectMenuDynamic === true)) {
                                                                                      return getListItemDescription(selected, dropdownData?.[TabElement[0].selectMenuDynamicType], 'name')
                                                                                    } else {
																																											return getLabelByValueRenderValue(TabElement[0].options, selected)
                                                                                    }
                                                                                }
                                                                            }
                                                                            onChange={(event) =>
                                                                                handleChangeVertualPayload(
                                                                                    deepCloneObj(vertualPayload),
                                                                                    event,
                                                                                    "selectField",
                                                                                    tabIndex,
                                                                                    0, //row position
                                                                                    TabElement,
                                                                                    deepCloneObj(vertualError)
                                                                                )
                                                                            }
                                                                            sx={{
                                                                                width: '100%',
                                                                                mt: '6px',
                                                                                "& fieldset": {
                                                                                    borderRadius: '8px',   
                                                                                    
                                                                                },
                                                                                "& fieldset":isRequiredFun(TabElement[0].required)
                                                                            }}
                                                                            multiple={((TabElement[0].isSingleSelect === 'true') || (TabElement[0].isSingleSelect === true)) ? false : true}
                                                                            size="small"
                                                                            labelId='demo-simple-select-label'
                                                                            id='demo-simple-select'
                                                                        >
                                                                            {(() => {
                                                                                try{
                                                                                    return (
                                                                                        (TabElement[0].isSelectMenuDynamic === 'true') || (TabElement[0].isSelectMenuDynamic === true)) ? (
                                                                                            dropdownData?.[TabElement[0].selectMenuDynamicType].map((e,i) => {
                                                                                                return <MenuItem key={e.id + "-" + i} value={e.id}><ListItemText primary={e.name} /></MenuItem>;
                                                                                            })
                                                                                        
                                                                                        ) : (
                                                                                            TabElement[0]?.options?.map(
                                                                                                (e, i) => {
                                                                                                return <MenuItem key={e.value + i} value={e.value}><ListItemText primary={e.label} /></MenuItem>;
                                                                                                },
                                                                                            )
                                                                                    )
                                                                                } catch(error) {
                                                                                    console.log(`${errorCodeMessage} 445131548jhbjh474545, left field tabIndex ${tabIndex}` )

                                                                                    toast.error(
                                                                                        <div style={{display:"flex", flexDirection:"row"}}>
                                                                                            <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                                                                                            &nbsp;&nbsp;{error.message}
                                                                                        </div>
                                                                                    );
                                                                                }
                                                                            })()}

                                                                        </Select>
                                                                    </FormControl>
                                                                </div>}
                                                                {(() => {
                                                                    try{
                                                                        return !((TabElement[0].isSingleSelect === 'true') || (TabElement[0].isSingleSelect === true)) && (vertualPayload[tabIndex][0].response?.selectResponse) && (vertualPayload[tabIndex][0].response.selectResponse.length > 0) && (
                                                                            <div className="selected-menu">
                                                                                {vertualPayload[tabIndex][0].response.selectResponse.map((e,i) => 
                                                                                    <div key={i} className="menu-item-container">
                                                                                        <div className="title">
                                                                                            {!((TabElement[0].isSelectMenuDynamic === 'true') || (TabElement[0].isSelectMenuDynamic === true)) ? (
                                                                                               getLabelByValue(TabElement[0], e)
                                                                                            ) : (
                                                                                                <>{(getObjectById(dropdownData[TabElement[0].selectMenuDynamicType], e))?.name}</>
                                                                                            )}
                                                                                        </div>
                                                                                        <div className="delete">
                                                                                            <RxCross1 />
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        ) 
                                                                    } catch(error) {
                                                                        console.log(`${errorCodeMessage} dvd15fv5d15v1er4ge8r, left field tabIndex ${tabIndex}` )

                                                                        toast.error(
                                                                            <div style={{display:"flex", flexDirection:"row"}}>
                                                                                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                                                                                &nbsp;&nbsp;{error.message}
                                                                            </div>
                                                                        );
                                                                    }
                                                                })()}
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                                {(TabElement[0].type === "date") && (
                                                    <>
                                                        <div className="field-row">
                                                            <div className="date-field">
                                                                
                                                                <div className="field-label" >
                                                                    {TabElement[0].label}
                                                                </div>
                                                                <div>
                                                                    <FormControl
                                                                    sx={{width: {md: '100%', sm: '100%'}}}
                                                                    >
                                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                            <DesktopDatePicker
                                                                                disabled={!TabElement[0].isEditable}
                                                                                value={vertualPayload[tabIndex][0].response.dateResponse}
                                                                                name={TabElement[0].name}
                                                                                onChange={(event) =>
                                                                                    handleChangeVertualPayload(
                                                                                        deepCloneObj(vertualPayload),
                                                                                        event,
                                                                                        "dateField",
                                                                                        tabIndex,
                                                                                        0, //row position
                                                                                        null, // TabElement
                                                                                        deepCloneObj(vertualError)
                                                                                    )
                                                                                }
                                                                                
                                                                                sx={{
                                                                                    width: '100%',
                                                                                    borderRadius: '8px',
                                                                                    "& .MuiOutlinedInput-root": {
                                                                                        borderRadius: '8px', 
                                                                                        border: "1px solid #F9F9F9",
                                                                                    },
                                                                                    
                                                                                }}
                                                                                renderInput={(params) => (
                                                                                    <TextField
                                                                                    size={"small"}
                                                                                    variant="outlined"
                                                                                    sx={{
                                                                                        pointerEvents: 'none',
                                                                                        '& .MuiOutlinedInput-root': {
                                                                                        'button': {
                                                                                            pointerEvents: 'all',
                                                                                        }},
                                                                                        mt: '6px',
                                                                                        width: "100%",
                                                                                        "& fieldset": {
                                                                                        border: "1px solid #dedede",
                                                                                        },
                                                                                        "& .css-k4qjio-MuiFormHelperText-root": {
                                                                                        backgroundColor: "#ffffff",
                                                                                        margin: "0px",
                                                                                        paddingLeft: "0.5rem",
                                                                                        },
                                                                                        "& .MuiOutlinedInput-root": {
                                                                                            // border: "1px solid #F9F9F9",
                                                                                            // borderColor: 'rgba(0, 0, 0, 0.23)',
                                                                                            // borderRadius: '8px',
                                                                                            'button': {
                                                                                                pointerEvents: 'all',
                                                                                            }
                                                                                        },
                                                                                        "& fieldset":isRequiredFun(TabElement[0].required)
                                                                                    }}

                                                                                    {...params}
                                                                                />
                                                                                )}
                                                                            />                     
                                                                        </LocalizationProvider>
                                                                    </FormControl>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </>
                                        )
                                    } catch(error) {
                                        console.log(`${errorCodeMessage} 5s4df31sdg5, left field TabElement ${tabIndex}` )

                                        toast.error(
                                            <div style={{display:"flex", flexDirection:"row"}}>
                                                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                                                &nbsp;&nbsp;{error.message}
                                            </div>
                                        );
                                    }
                                })()}
                            </div>
                            <div className=" d-flex justify-content-between">
                                <div className="field-error-message">
                                    {
                                        (!Array.isArray(vertualError[tabIndex][0])) && vertualError[tabIndex][0].responseError.isError && (
                                            <Typography>{vertualError[tabIndex][0].responseError.errorMessage}</Typography>
                                        )
                                    }
                                    
                                </div>
                                {!(pageMoodViewOrEdit === 'view') && isTheirStringLimit(TabElement[0]) && <div className="cha-left-indicator">
                                        {showCharLeft(TabElement[0], vertualPayload[tabIndex][0].response?.stringResponse?.length)}
                                    </div>}
                            </div>
                            
                            

                        </>
                    )
                )}
              </div>
            )
            );
          }
    };


    return (
        <div className={`emp-goals-form-page${(pageMoodViewOrEdit === 'view') ? '-view' : ''}`}>

            <div className="goals-form-page-container">
                <div className="form-page-container-margin">


                    {!(pageMoodViewOrEdit === 'view') && <div className="form-goal-head">
                        <div className="goal-head">
                            <div className="head-title">
                                {(() => {
                                    if((isReplica === true) || (isReplica === 'true') || (id === 'newform')) {
                                        return 'Add a goal'
                                    } else {
                                        return 'Editing a goal'
                                    }
                                })()}
                            </div>
                            <div className="head-delete">
                                <div className="delete-icon">
                                    <MdDelete />
                                </div>
                                <div className="title">
                                    Delete this goal
                                </div>
                            </div>

                        </div>
                    </div>}
             

              
                    <div className="form-goal-contant">
                        <div className="goal-contant">

                            <>

                            {(!isLoading && formData && (formData.responseFormField.length > 0)) &&
                                createDynamicForm(
                                    formData.responseFormField
                                )
                            }

                            {isLoading && DndFormSkeleton()}

                          
                            
                            </>

                            
                        </div>
                    </div>

                

                
                  

                    <div className="d-flex justify-content-end gap-3 pt-5 main-btn">
                        {/* <div className="valuesBtn draft" style={styleSaveAsDraft()}>Auto Save 2 sec</div> */}
                        {!(pageMoodViewOrEdit === 'view') && <button
                            style={styleSaveAsDraft()}
                            className="valuesBtn save-draft"
                            onClick={() => history.push('/employee-goals')}
                        >
                            {submitLoader ? (
                            <CircularProgress size={29} sx={{ color: "#ffffff" }} />
                            ) : (
                            // "Save as draft"
                            "Cancel"
                            )}
                        </button>}

                        {!(pageMoodViewOrEdit === 'view') && <button
                            style={styleSaveAsDraft()}
                            className="valuesBtn next"
                            onClick={() => handleSubmit('DRAFT')} // true for is draft
                        >
                            {submitLoader ? (
                            <CircularProgress size={29} sx={{ color: "#ffffff" }} />
                            ) : (
                            "Draft"
                            )}
                        </button>}


                        {!(pageMoodViewOrEdit === 'view') && <button
                            style={styleSubmit()}
                            className="valuesBtn next"
                            onClick={() => handleValidate(false)} // false for is draft
                        >
                            {submitLoader ? (
                            <CircularProgress size={29} sx={{ color: "#ffffff" }} />
                            ) : (
                            "Submit"
                            )}
                        </button>}
                    </div>

                    
            {showConfirmationPopup && 
            <DialogPopup openDialog={showConfirmationPopup} setOpenDialog={setShowConfirmationPopup} redirect={false} >
                <div className="feedback-dialog-wrap">
                <img src={checkIcon} className="checkIcon"></img>
                <div className="content">
                Are you sure you want to submit? Once submitted you will not be able to Edit or make any further changes. Please click on Confirm to proceed or Cancel to edit.
                {/* Thank you for completing the appraisal. Your feedback is important. Please note that once submitted, revisions will not be feasible. Click 'Confirm' if you intend to proceed. If you want to further edit or revise, click 'Cancel' */}
                </div>

                <div className="multi-btn" >
                    <button className="button"
                        onClick={() => {
                            handleSubmitFeedback('SUBMIT')
                        }}
                    >
                        Confirm
                    </button>
                    <button className="button"
                        onClick={() => setShowConfirmationPopup(false)}>
                        Cancel
                    </button>
                </div>

                </div>
            </DialogPopup>}
        

                    

                </div>


            </div>

        </div>
    )

    
}

export default GoalsForm;
